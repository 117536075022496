import React from 'react'

import { styled } from '@mui/material/styles'
import { keyframes } from "@emotion/react"
import Fade from 'react-reveal/Fade'
import Divider from '@mui/material/Divider'
import { map } from 'lodash'

const FloatingKeyframes = keyframes`
0% { transform: translate(0,  0px); }
50%  { transform: translate(0, 4px); }
100%   { transform: translate(0, -0px); }   
`

const KeyframedCard = styled(`div`)((({ floatDuration = 5 }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: '0 0 0',
  justifyContent: 'center',
  alignItems: 'center',
  justifyItems: 'center',
  textAlign: 'center',
  fontSize: '32px',
  animationName: FloatingKeyframes,
  animationDuration: `${floatDuration}s`,
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
  background: '#000000',
  color: '#FFAA00',
  padding: '10px',
  borderRadius: '0px 0px 15px 15px',
  margin: '4px',
})))

export const ResponsiveKeyframedCard = styled(KeyframedCard)`
  @media only screen and (max-width: 700px) {
      font-size: 32px;
  }

  @media only screen and (max-width: 650px) {
      font-size: 20px;
  }

  @media only screen and (max-width: 500px) {
      font-size: 20px;
  }
`

export const StyledWorkItemContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: calc(60vw + 4px);
  align-content: center;
  justify-content: center;
  align-items: center;
  border: 3px black;
  border-style: hidden double double double;
  border-radius: 0px 0px 5px 5px;
  background-color: #ffffff;
  
  @media only screen and (max-width: 820px) {
    width: 98vw;
  }
`

const StyledWorkExperienceContainerWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 2px;
`

const StyledWorkItemContainerTitle = styled('div')`
  text-align: center;
  width: 100%;
  background-color: black;
  color: white;
  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
  animation: shimmer 20s infinite;
  @keyframes shimmer {
    8% {-webkit-mask-position:left}
    100% {-webkit-mask-position:left}
  }
  @media only screen and (max-width: 700px) {
      font-size: 16px;
  }

  @media only screen and (max-width: 300px) {
      font-size: 12px;
  }
`

const StyledItem = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 430px) {
      text-align: center;
      line-height: 1.3;
  }
`

const StyledCompany = styled('div')`
  position: absolute;
  top: 16px;
  left: 6px;
  font-size: 10px;
  @media only screen and (max-width: 470px) {
      text-align: center;
      position: relative;
      top: auto;
      left: auto;
  }
`

const StyledTitle = styled('div')`
  position: absolute;
  top: 0px;
  left: 4px;
  @media only screen and (max-width: 470px) {
      text-align: center;
      position: relative;
      top: auto;
      left: auto;
  }
`

const StyledDate = styled('div')`
  position: absolute;
  top: 0px;
  right: 4px;
  @media only screen and (max-width: 470px) {
    text-align: center;
    position: relative;
    right: auto;
    top: 4px;
  }
`

const StyledRevealWrapper = styled('div')`
  width: 100%;
  font-size: 14px;
`

const StyledHighlight = styled('div')`
  padding-top: 20px;

  @media only screen and (max-width: 470px) {
    padding-top: 0px;
    font-size: 12px;
    text-align: left;
  }

  @media only screen and (max-width: 320px) {
    font-size: 10px;
  }
`

export const WorkExperienceContainer = ({children, title, ...rest}) => (
  <StyledWorkExperienceContainerWrapper {...rest}>
    <StyledWorkItemContainerTitle>
      {title}
    </StyledWorkItemContainerTitle>
    <StyledWorkItemContainer {...rest}>
      {children}
    </StyledWorkItemContainer>
  </StyledWorkExperienceContainerWrapper>
)

export const WorkItem = ({
  company = "",
  title = "",
  from = "",
  to = "",
  highlights = [],
  delay = 0,
  topDivider = false,
}) => (
  <StyledRevealWrapper>
    <Fade delay={delay} sx={{width: "100%", height: "100%"}}>
      <StyledItem>
        {topDivider && <Divider /> }
        <StyledDate>
          {from} {!!to &&"-"} {to}
        </StyledDate>
        <StyledTitle>
          {title}
        </StyledTitle>
        <StyledCompany>
          {company}
        </StyledCompany>
        <StyledHighlight>
          <ul>
          {
            map(highlights, (highlight, index) => (
              <li key={`highlights_${index}`}>
                {highlight}
              </li>
            ))
          }
          </ul>
        </StyledHighlight>
      </StyledItem>
    </Fade>
  </StyledRevealWrapper>
)

export default WorkItem