import React, { useRef } from 'react'

import ReactTooltip from 'react-tooltip'
import Box from '@mui/material/Box'
import { ThemeProvider, styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import CssBaseline from '@mui/material/CssBaseline'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import TopBar from './sections/TopBar'
import HomeSection from './sections/Home'
import AboutSection from './sections/About'
import ResumeSection from './sections/Resume'
import ProjectsSection from './sections/Projects'
import EntertainmentSection from './sections/Entertainment'
import ContactSection from './sections/Contact'
import { theme } from './constants/Themes'

const InnerContainer = styled(Paper)(() =>({
  height: '1600px'
}))

const App = () => {
  const refs = {
    home: useRef(null),
    about: useRef(null),
    resume: useRef(null),
    projects: useRef(null),
    entertainment: useRef(null),
    contact: useRef(null),
  }
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ReactTooltip />
      <div className="App" sx={{backgroundColor: '#FAFAFA'}}>
        <TopBar refs={refs} />
        <Box sx={{ flexGrow: 1, index: 0, marginTop: '64px' , backgroundColor: '#FAFAFA', paddingTop: '0px'}}>
            <InnerContainer sx={{display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', minWidth: '110px', backgroundColor: '#FAFAFA', height: 'auto'}}>
              <HomeSection innerRef={refs.home} />
              <AboutSection innerRef={refs.about} />
              <ResumeSection innerRef={refs.resume} />
              <ProjectsSection innerRef={refs.projects} />
              <EntertainmentSection innerRef={refs.entertainment} />
              <ContactSection innerRef={refs.contact} />
            </InnerContainer>
        </Box>
      </div>
    </ThemeProvider>
  );
}

export default App;
