import React from 'react'

import { map } from 'lodash'
import { Img } from 'react-image'
import { styled } from '@mui/material/styles'
import { RevealingLightSection, DarkHeader } from '../components/Section'
import { hostUrls } from '../config/settings'

const ICON_DIMENSION = "36px"

const Item = styled('div')`
    margin-top: 14px;
    margin-bottom: 14px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    align-content: center;
    flex-wrap: nowrap;
    font-size: 28px;
    @media only screen and (max-width: 500px) {
        font-size: 16px;
        align-items: flex-end;
    }
`

const ContactContainer = styled('div')`
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20vh;
`

const ContactSection = ({ innerRef }) => (
    <div ref={innerRef}>
        <RevealingLightSection pageview="/contact">
            <DarkHeader> Contact Me </DarkHeader>
            <ContactContainer>
                <Item>
                    <Img
                        alt=""
                        width={ICON_DIMENSION}
                        height={ICON_DIMENSION}
                        src={map(hostUrls, (url) => `${url}/icons/mail.png`)}
                        style={{ width: ICON_DIMENSION, height: ICON_DIMENSION, marginRight: 4 }}
                    />
                    <a href="mailto: matt@almenshad.com" target="_blank" rel="noreferrer">
                        matt@almenshad.com
                    </a>
                </Item>
                <Item>
                    <Img
                        alt=""
                        width={ICON_DIMENSION}
                        height={ICON_DIMENSION}
                        src={map(hostUrls, (url) => `${url}/icons/linkedin.png`)}
                        style={{ width: ICON_DIMENSION, height: ICON_DIMENSION, marginRight: 4 }}
                    />
                    <a href="https://www.linkedin.com/in/almenshad" target="_blank" rel="noreferrer">
                        linkedin.com/in/almenshad
                    </a>
                </Item>
            </ ContactContainer>
        </RevealingLightSection>
    </div>
)

export default ContactSection