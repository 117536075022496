import React from 'react'

import { map, get } from 'lodash'
import { styled } from '@mui/material/styles'
import Divider from '@mui/material/Divider'
import { DarkHeader, RevealingDimSection } from '../components/Section'
import Badge, { BadgeContainer } from '../components/resume/Badge'
import WorkItem, { WorkExperienceContainer } from '../components/resume/WorkItem'
import Hackathon, { HackathonExperienceContainer } from '../components/resume/Hackathon'
import {
    WORK_EXPERIENCE,
    LANGUAGES,
    TOOLS_AND_FRAMEWORKS,
    DATABASES, TECHNOLOGIES,
    HACKATHONS,
} from '../constants/Resume'
import { hostUrls } from '../config/settings'

export const StyledSuperContainer = styled(`div`)`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 820px) {
        flex-direction: column;
    }
`

export const StyledVerticalContainer = styled(`div`)`
  display: flex;
  flex-direction: column;
`

export const StyledDegreeContainer = styled(BadgeContainer)`
    text-align: center;
    background-color: rgba(0, 130, 30, 0.35);
    font-size: 16px;
    @media only screen and (max-width: 1050px) {
        font-size: 14px;
    }
    @media only screen and (max-width: 900px) {
        font-size: 12px;
    }
`

const FromDivider = styled(Divider)`
    width: 95%;
`

const StyledDarkHeader = styled(DarkHeader)`
    display: flex;
    flex-direction: column;
`

const DownloadLink = styled('div')`
    font-size: 12px;
    color: #0000EE;
`

const ResumeSection = ({ innerRef }) => (
    <div ref={innerRef}>
        <RevealingDimSection pageview="/resume">
            <StyledDarkHeader>
                Resume
                {
                    map(hostUrls, (url, index) => (
                        <div key={`RESUME_LINK_${url}`}>
                            {!!index && <Divider />}
                            <DownloadLink>
                                <a href={`${url}/resume.pdf`} rel="noreferrer" target="_blank" download>
                                    {!!index ? `Alternative Download #${index} (PDF)` : "Download (PDF)"}
                                </a>
                            </DownloadLink>
                        </div>
                    ))
                }
            </StyledDarkHeader>
            <StyledVerticalContainer>
                <WorkExperienceContainer title="Work Experience">
                    {
                        map(WORK_EXPERIENCE, (experience, index) => (
                            <WorkItem
                                key={`WORK_EXPERIENCE_${index}`}
                                company={get(experience, 'company')}
                                title={get(experience, 'title')}
                                from={get(experience, 'from')}
                                to={get(experience, 'to')}
                                highlights={get(experience, 'highlights')}
                                delay={index*10}
                                topDivider={!!index}
                            />
                        ))
                    }
                </WorkExperienceContainer>
            </StyledVerticalContainer>
            <StyledSuperContainer>
                <StyledVerticalContainer>
                    <StyledDegreeContainer title="Education">
                        <div>BS in ‎</div>
                        <div>Computer and Information Science</div>
                        <FromDivider>
                            from the
                        </FromDivider>
                        <div>University of Oregon</div>
                    </StyledDegreeContainer>
                    <BadgeContainer title="Languages">
                        {
                            map(LANGUAGES, (languages, index) => {
                                var level = get(languages, 'level', 0)
                                return (
                                    !!level && <Badge
                                        key={`LANGUAGES_${index}`}
                                        title={get(languages, 'title')}
                                        Icon={get(languages, 'icon')}
                                        delay={index*10}
                                        level={level}
                                    />
                                )
                            })
                        }
                    </BadgeContainer>
                    <BadgeContainer title="Databases">
                        {
                            map(DATABASES, (database, index) => {
                                var level = get(database, 'level', 0)
                                return (
                                    !!level && <Badge
                                        key={`DATABASES_${index}`}
                                        title={get(database, 'title')}
                                        Icon={get(database, 'icon')}
                                        delay={index*10}
                                        level={level}
                                    />
                                )
                            })
                        }
                    </BadgeContainer>
                    <BadgeContainer title="Technologies">
                        {
                            map(TECHNOLOGIES, (technology, index) => {
                                var level = get(technology, 'level', 0)
                                return (
                                    !!level && <Badge
                                        key={`TECHNOLOGIES_${index}`}
                                        title={get(technology, 'title')}
                                        Icon={get(technology, 'icon')}
                                        delay={index*10}
                                        level={level}
                                    />
                                )
                            })
                        }
                    </BadgeContainer>
                </ StyledVerticalContainer>
                <StyledVerticalContainer>
                <BadgeContainer title="Tools & Frameworks">
                        {
                            map(TOOLS_AND_FRAMEWORKS, (toolOrFramework, index) => {
                                var level = get(toolOrFramework, 'level', 0)
                                return (
                                    !!level && <Badge
                                        key={`TOOLS_AND_FRAMEWORKS_${index}`}
                                        title={get(toolOrFramework, 'title')}
                                        Icon={get(toolOrFramework, 'icon')}
                                        delay={index*10}
                                        level={level}
                                    />
                                )
                            })
                        }
                    </BadgeContainer>
                    <HackathonExperienceContainer title="Hackathons">
                        {
                            map(HACKATHONS, (hackathon, index) => (
                                <Hackathon
                                    key={`HACKATHONS${index}`}
                                    company={get(hackathon, 'location')}
                                    title={`${get(hackathon, 'connection')} ${get(hackathon, 'name')}`}
                                    from={get(hackathon, 'date')}
                                    delay={index*10}
                                    topDivider={!!index}
                                />
                            ))
                        }
                    </HackathonExperienceContainer>
                </ StyledVerticalContainer>
            </StyledSuperContainer>
        </RevealingDimSection>
    </div>
)

export default ResumeSection