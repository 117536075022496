import React from 'react'

import { styled } from '@mui/material/styles'
import { keyframes } from "@emotion/react"
import Fade from 'react-reveal/Fade'
import Divider from '@mui/material/Divider'

const FloatingKeyframes = keyframes`
0% { transform: translate(0,  0px); }
50%  { transform: translate(0, 4px); }
100%   { transform: translate(0, -0px); }   
`

const KeyframedCard = styled(`div`)((({ floatDuration = 5 }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: '0 0 0',
  justifyContent: 'center',
  alignItems: 'center',
  justifyItems: 'center',
  textAlign: 'center',
  fontSize: '32px',
  animationName: FloatingKeyframes,
  animationDuration: `${floatDuration}s`,
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
  background: '#000000',
  color: '#FFAA00',
  padding: '10px',
  borderRadius: '0px 0px 15px 15px',
  margin: '4px',
})))

export const ResponsiveKeyframedCard = styled(KeyframedCard)`
  @media only screen and (max-width: 700px) {
      font-size: 32px;
  }

  @media only screen and (max-width: 650px) {
      font-size: 20px;
  }

  @media only screen and (max-width: 500px) {
      font-size: 20px;
  }
`

export const StyledHackathonContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 30vw;
  align-content: center;
  justify-content: center;
  align-items: center;
  border: 3px black;
  border-style: hidden double double double;
  border-radius: 0px 0px 5px 5px;
  background-color: #ffffff;
  @media only screen and (max-width: 820px) {
    width: 98vw;
  }
`

const StyledHackathonExperienceContainerWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 2px;
`

const StyledHackathonContainerTitle = styled('div')`
  text-align: center;
  width: 30vw;
  background-color: black;
  color: white;
  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
  animation: shimmer 20s infinite;
  @keyframes shimmer {
    8% {-webkit-mask-position:left}
    100% {-webkit-mask-position:left}
  }
  @media only screen and (max-width: 820px) {
    width: 98vw;
  }
  @media only screen and (max-width: 700px) {
      font-size: 16px;
  }
  @media only screen and (max-width: 300px) {
      font-size: 12px;
  }
`

const StyledItem = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 30vw;
  height: 100%;
  min-height: 34px;
  @media only screen and (max-width: 820px) {
    width: 98vw;
  }
  @media only screen and (max-width: 430px) {
      text-align: center;
      line-height: 1.2;
  }
`

const StyledCompany = styled('div')`
  position: absolute;
  top: 16px;
  left: 12px;
  font-size: 10px;
  @media only screen and (max-width: 470px) {
      text-align: center;
      position: relative;
      top: auto;
      left: auto;
  }
`

const StyledTitle = styled('div')`
  position: absolute;
  top: 0px;
  left: 8px;
  @media only screen and (max-width: 470px) {
      text-align: center;
      position: relative;
      top: auto;
      left: auto;
  }
`

const StyledDate = styled('div')`
  position: absolute;
  bottom: 0px;
  right: 8px;
  @media only screen and (max-width: 470px) {
    text-align: center;
    position: relative;
    right: auto;
    bottom: auto;
  }
`

const StyledRevealWrapper = styled('div')`
  width: 30vw;
  @media only screen and (max-width: 820px) {
    width: 98vw;
  }
  font-size: 14px;
`

export const HackathonExperienceContainer = ({children, title, ...rest}) => (
  <StyledHackathonExperienceContainerWrapper {...rest}>
    <StyledHackathonContainerTitle>
      {title}
    </StyledHackathonContainerTitle>
    <StyledHackathonContainer {...rest}>
      {children}
    </StyledHackathonContainer>
  </StyledHackathonExperienceContainerWrapper>
)

const StyledFade = styled(Fade)`
  width: 30vw;
  @media only screen and (max-width: 820px) {
    width: 98vw;
  }
  height: 100%;
`
export const Hackathon = ({
  company = "",
  title = "",
  from = "",
  to = "",
  highlights = [],
  delay = 0,
  topDivider = false,
}) => (
  <StyledRevealWrapper>
    <StyledFade delay={delay}>
      <StyledItem>
        {topDivider && <Divider /> }
        <StyledDate>
          {from} {!!to && "-"} {to}
        </StyledDate>
        <StyledTitle>
          {title}
        </StyledTitle>
        <StyledCompany>
          {company}
        </StyledCompany>
      </StyledItem>
    </StyledFade>
  </StyledRevealWrapper>
)

export default Hackathon