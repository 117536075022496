import React from 'react'

import { Img } from 'react-image'

import { RevealingLightHeader, DarkSection } from '../components/Section'
import { styled } from '@mui/material/styles'
import { keyframes } from "@emotion/react"
import useMediaQuery from '@mui/material/useMediaQuery'
import Fade from 'react-reveal/Fade'
import { get, map } from 'lodash'

import { hostUrls } from '../config/settings'
import { theme } from '../constants/Themes'

const YEARS_SINCE_FIRST_COMPUTER = (new Date()).getFullYear() - (new Date('01/01/2006')).getFullYear()

const ProfileBackground = styled('div')(
    () => `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 242px;
    height: 306px;
    color: white;
    font-family: 'Raleway';
    font-size: 2.5rem;

    --borderWidth: 3px;
    background: #1D1F20;
    position: relative;
    border-radius: var(--borderWidth);
    `
)

const ProfileBackgroundKeyframes = keyframes`
0% {
    background-position: 0% 50%;
}
50% {
    background-position: 100% 50%;
}
100% {
    background-position: 0% 50%;
}
`

const KeyFramedProfileBackground = styled(ProfileBackground)({
    "&::after": {
        '--borderWidth': '3px',
        content: '""',
        position: 'absolute',
        top: 'calc(-1 * var(--borderWidth))',
        left: 'calc(-1 * var(--borderWidth))',
        height: 'calc(100% + var(--borderWidth) * 2)',
        width: 'calc(100% + var(--borderWidth) * 2)',
        background: 'linear-gradient(60deg, #cecece, #494949, #9c9c9c, #69666a, #ffffff, #616161, #ffffff, #000000)',
        borderRadius: 'calc(2 * var(--borderWidth))',
        animation: `${ProfileBackgroundKeyframes} 3s ease alternate infinite`,
        backgroundSize: '300% 300%',
    }
})

const StorySection = styled(`div`)(() => `
    padding-left: 20vw;
    padding-right: 20vw;
`)

const StyledParagraph = styled('p')(({ fontSize }) => ({
    fontSize
}))

const StoryParagraph = (props) => {
    const isBtMd = useMediaQuery(theme.breakpoints.up('md'))
    const fontSize = isBtMd ? 22 : 16

    return (
        <Fade delay={200} duration={3000}>
            <StyledParagraph fontSize={fontSize}>
                {get(props, 'children')}
            </StyledParagraph>     
        </Fade>
    )
}
const AboutSection = ({ innerRef }) => (
    <div ref={innerRef}>
        <DarkSection pageview="/about">
            <RevealingLightHeader> About Me </RevealingLightHeader>
            <KeyFramedProfileBackground data-tip="Photograph by Emery Thanathiti of emeryth.com">
                <Img
                    alt=""
                    src={map(hostUrls, (url) => `${url}/profile.png`)}
                    style={{zIndex: 1}}
                />
            </KeyFramedProfileBackground>
            <StorySection>
                <StoryParagraph>
                    I found my passion for software {YEARS_SINCE_FIRST_COMPUTER} years ago when I bought my first computer and immediately used it to explore its inner workings. Little did I know that I would make my first website in less than a year, writing basic HTML and copying different javascript snippets and modifying them to fit my vision.
                </StoryParagraph>     
                <StoryParagraph>
                    What started as a curious exploration grew into an addictive obsession. I found myself learning and building web-based applications in PHP then soon creating servers and desktop clients in Java 6 throughout my middle-school years. It was around that time that I also discovered my passion for entrepreneurship as I started a small, personal business to fix computers and build websites. Soon after, I started working as a computer lab assistant in high school as I quickly learned that I genuinely enjoyed teaching.
                </StoryParagraph>     
                <StoryParagraph>
                    All the while, I continued to hone my technical skills. By the end of high school, I had picked up C, C++ and Python working on many small projects for educational purposes. From there on, My love for software development as well as teaching has been the main driver of my career even after high school.
                </StoryParagraph>     
                <StoryParagraph>
                    Throughout my academic career while pursuing a B.S. degree in computer science at the University of Oregon, I have been consistently praised as a diligent and disciplined individual by my professors and peers. While working on academic and extracurricular projects, I have developed proven team building, clear communication and adaptability skills, which I hope to use while building my own software company one day.
                </StoryParagraph>     
                <StoryParagraph>
                    As of July 2018, I joined a software venture development firm where I work with and learn from a team of world-class engineers and designers with a passion for delivering highly scalable, enterprise grade products and platforms. To this day, I continue to learn and grow working with different teams and  filling various roles on different projects.
                </StoryParagraph>     
                <StoryParagraph>
                    When I am not working, I enjoy going on adventures with my friends, petting kittens, walking in the rain, watching TV or listening to audio books :)
                </StoryParagraph>     
            </StorySection>
        </DarkSection>
    </div>
)

export default AboutSection