import { map, get } from 'lodash'
import { hostUrls } from '../config/settings'

export const AUDIO_BOOKS_PICTURES_PATH = `audio_book_posters`

export const getAudioBookPosterWithPath = (poster) => `${AUDIO_BOOKS_PICTURES_PATH}/${poster}`
export const getAudioBookPosterUrls = (poster) => map(hostUrls, (url) => `${url}/${getAudioBookPosterWithPath(poster)}`)

export const AUDIO_BOOKS = [
    {
        "title": "Cradle",
        "poster": "cradle.png"
    },
    {
        "title": "Wizarding World",
        "poster": "wizarding_world.png"
    },
    {
        "title": "Bobiverse",
        "poster": "bobiverse.png"
    },
    {
        "title": "Chaos Seeds",
        "poster": "chaos_seeds.png"
    },
    {
        "title": "Ripple System",
        "poster": "ripple_system.png"
    },
    {
        "title": "Dungeon Crawler Carl",
        "poster": "dungeon_crawler_carl.png"
    },
    {
        "title": "Unorthodox Farming",
        "poster": "unorthodox_farming.png"
    },
    {
        "title": "Spellmonger",
        "poster": "spellmonger.png"
    },
    {
        "title": "Threadbare Series",
        "poster": "threadbare_series.png"
    },
    {
        "title": "The Hard Thing About Hard Things",
        "poster": "the_hard_thing_about_hard_things.png"
    },
    {
        "title": "Product-Led Growth",
        "poster": "product_led_growth.png"
    },
    {
        "title": "The Magicians",
        "poster": "the_magicians.png"
    },
    {
        "title": "The Ten Realms 1",
        "poster": "the_ten_realms_1.png"
    },
    {
        "title": "Expeditionary Force 1",
        "poster": "expeditionary_force_1.png"
    },
    {
        "title": "Super Sales on Super Heroes",
        "poster": "super_sales_on_super_heroes.png"
    },
    {
        "title": "Super Powereds 1",
        "poster": "super_powereds_1.png"
    },
    {
        "title": "Shadows of the Apt 1",
        "poster": "shadows_of_the_apt_1.png"
    },
    {
        "title": "The Last Reaper 1",
        "poster": "the_last_reaper_1.png"
    },
    {
        "title": "The Richest Man in Babylon",
        "poster": "the_richest_man_in_babylon.png"
    },
    {
        "title": "Dune",
        "poster": "dune.png"
    },
    {
        "title": "Turn the Ship Around",
        "poster": "turn_the_ship_around.png"
    },
    {
        "title": "Elantris",
        "poster": "elantris.png"
    },
    {
        "title": "Stalling for Time",
        "poster": "stalling_for_time.png"
    },
    {
        "title": "The Great Gatsby",
        "poster": "the_great_gatsby.png"
    }
]

export const getAudioBookTitle = (index) => get(AUDIO_BOOKS, `${index}.title`, "unkown audio book")

export default AUDIO_BOOKS