import React from 'react'

import { styled } from '@mui/material/styles'

const StyledLoader = styled('div')`
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
`

const StandardLoader = ({
  loadingDarkMode,
  width = 'auto',
  height = 'auto',
}) => {

  const color = loadingDarkMode ? 'white' : 'black'
  const background = loadingDarkMode ? 'black' : 'white'

  return (
    <StyledLoader
      style={{
        background,
        color,
        width,
        height,
        borderWidth: 4,
        borderColor: color,
        borderStyle: 'double',
      }}
    >
      Loading...
    </StyledLoader>
  )
}
export default StandardLoader
