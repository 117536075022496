import React, { useRef, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { trim, capitalize } from 'lodash'
import { useOnScreen } from './onScreenHook'

const withPageview = (WrappedComponent) => ({
  pageview,
  ...otherProps
}) => {

  const ref = useRef()
  const isOnScreen = useOnScreen(ref, 0.1)

  useEffect(() => {
    if (isOnScreen) ReactGA.send({ hitType: "pageview", page: pageview, title: capitalize(trim(pageview, "/")) })
  }, [isOnScreen, pageview]);

  return <WrappedComponent {...otherProps} ref={ref}  />
}

export default withPageview