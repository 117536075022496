import React, { useState, useRef, useEffect } from 'react'

import {Img} from 'react-image'
import ReactGA from 'react-ga4'
import { Carousel } from 'react-responsive-carousel'
import { map, get } from 'lodash'
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Loader from '../components/Loader'
import { RevealingLightSection } from '../components/Section'
import { CarouselAutoPlayControl, StyledCarouselSectionContainer } from '../components/Carousel'
import { theme } from '../constants/Themes'
import QUOTES, { getAuthorNameByKey, getAuthorPortraitUrls } from '../constants/Quotes'
import { CAROUSEL_LOAD_DISTANCE } from '../config/settings'
import { useOnScreen } from '../utils/onScreenHook'

const Paragraph = styled(Typography)`
    margin-bottom: 1vw;
    margin-left: 10vw;
    margin-right: 10vw;
    text-align: center;
    font-weight: bold;
`

const StyledRevealingLightSection = styled(RevealingLightSection)`
    justify-content: center;
    padding-top: 10px
`

const StyledCarouselPage = styled("div")(
    ({ fontSize }) => ({
        backgroundColor: '#f2f1f0',
        paddingTop: 10,
        height: '100%',
        color: 'black',
        fontSize,
        borderBottom: "3px black double",
        borderTop: "3px black double",
    })
)

const StyledCarousel = styled(Carousel)`
    .carousel-status {
        color: white;
        right: auto;
        font-size: 16px;
        margin-top: 0px;
        padding-left: 4px;
        padding-right: 8px;
        border-radius: 0px 0px 10px 0px;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 4;
    }
`

const QuoteText = styled('div')`
    padding-right: 16vw;
    padding-left: 16vw;
    margin-top: 10px;
    @media only screen and (max-width: 1000px) {
        padding-right: 2vw;
        padding-left: 2vw;
    }
`

const HomeSection = ({ innerRef }) => {
    const [isHomeAutoPlayEnabled, setHomeAutoPlayEnabled] = useState(true)

    const isBtMd = useMediaQuery(theme.breakpoints.up('md'))
    const pictureWidth = isBtMd ? 275 : 128
    const pictureHieght = isBtMd ? 333: 155
    const fontSize = isBtMd ? 22 : 16

    const carouselSectionRef = useRef()
    const isVisible = useOnScreen(carouselSectionRef)
    
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        ReactGA.event({
            category: "Quote Carousel",
            action: "Displayed",
            label: currentIndex + 1,
            nonInteraction: true,
          })
    }, [currentIndex])

    return (
        <div ref={innerRef}>
            <StyledRevealingLightSection pageview="/home">
                <Paragraph variant={isBtMd ? "h6": "body1"} >
                    What purpose do landing pages on personal websites serve? I propose their purpose is to serve as a means for quickly getting to know someone; And what better way to get to know someone than to experience what brings them joy! What brings me joy, you ask? TV quotes. An insurmountable amount of hand-picked, absolute-favourite TV quotes 😄
                </Paragraph>
                <StyledCarouselSectionContainer ref={carouselSectionRef}>
                    <CarouselAutoPlayControl isAutoPlayEnabled={isHomeAutoPlayEnabled} setAutoPlayEnabled={setHomeAutoPlayEnabled} />
                    <StyledCarousel
                        onChange={(index) => setCurrentIndex(index)}
                        autoPlay={isHomeAutoPlayEnabled && isVisible}
                        infiniteLoop={true}
                        interval={4000}
                        showIndicators={false}
                        useKeyboardArrows={true}
                        width="80vw"
                        showThumbs={false}
                        stopOnHover={false}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={30}
                    >
                        {map(QUOTES, (quoteData, index) => {
                        var authorKey = get(quoteData, 'authorKey')
                        var quote = get(quoteData, 'quote')
                        var authorName = getAuthorNameByKey(authorKey)
                        return (
                            <StyledCarouselPage fontSize={fontSize} key={`QUOTE_${authorKey}_${index}`}>
                                <div style={{ height: pictureHieght, display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                                    {(index - currentIndex < CAROUSEL_LOAD_DISTANCE) &&
                                        <Img
                                            loader={<Loader width={pictureWidth} height={pictureHieght} />}
                                            unloader={<Alert severity="error">Could not load the portrait of {authorName}</Alert>}
                                            alt=""
                                            width={pictureWidth}
                                            height={pictureHieght}
                                            src={getAuthorPortraitUrls(authorKey)}
                                            style={{ width: pictureWidth, height: pictureHieght }}
                                        />
                                    }
                                </div>
                                <QuoteText>“{quote}”</QuoteText>
                                <div style={{ textAlign: 'right', paddingRight: '28vw', paddingTop: 10}}>— {authorName}</div>
                            </StyledCarouselPage>
                        )
                        })}
                    </StyledCarousel>
                </ StyledCarouselSectionContainer>
            </StyledRevealingLightSection>
        </div>
    )
}

export default HomeSection