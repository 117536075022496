import React, { useState, useRef, useEffect } from 'react'

import { map, get } from 'lodash'
import { Carousel } from 'react-responsive-carousel'
import {Img} from 'react-image'
import ReactGA from 'react-ga4'
import Alert from '@mui/material/Alert';
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from '@mui/material/styles'
import Divider from '@mui/material/Divider'

import Loader from '../components/Loader'
import { theme } from '../constants/Themes'
import { RevealingLightSection, DarkHeader } from '../components/Section'
import { StyledCarouselCaption, CarouselAutoPlayControl, StyledCarouselSectionContainer } from '../components/Carousel'
import PROJECTS, { getProjectPosterUrls } from '../constants/Projects'
import { CAROUSEL_LOAD_DISTANCE } from '../config/settings'
import { useOnScreen } from '../utils/onScreenHook'

const StyledCarouselPage = styled("div")`
    background-color: #000000;
    height: calc(60vw * 0.75);
    max-height: 450px;
    color: white;
    font-size: 22px;
`

const StyledCarousel = styled(Carousel)`
    max-width: 600px;
    .carousel-status {
        color: white;
        right: auto;
        font-size: 24px;
        margin-top: 0px;
        background-color: rgba(0, 0, 0, 0.4);;
    }
    @media only screen and (max-width: 850px) {
        font-size: 12px;
        .carousel .control-dots .dot {
            margin: 0px 4px;
        }
    }
    @media only screen and (max-width: 400px) {
        .carousel .control-dots .dot {
            margin: 0px 3px;
        }
    }
    @media only screen and (max-width: 350px) {
        .carousel .control-dots .dot {
            margin: 0px 2px;
        }
    }
`

const StyledCarouselSection = styled('div')`
    width: calc(60vw);
    max-width: 600px;
    display: flex;
    flex-direction: column;
    background-color: black;
    margin-bottom: 4px;
    position: relative;
`

const StyledProjectDescriptionCaption = styled(StyledCarouselCaption)`
    width: 95%;
    max-width: 1000px;
    text-align: center;
    position: relative;
    padding-left: 4px;
    padding-right: 4px;
    background-color: #ffffff;
    color: black;
    @media only screen and (max-width: 940px) {
        font-size: 21px;
    }
    @media only screen and (max-width: 900px) {
        font-size: 20px;
    }
    @media only screen and (max-width: 855px) {
        font-size: 19px;
    }
    @media only screen and (max-width: 815px) {
        font-size: 18px;
    }
    @media only screen and (max-width: 772px) {
        font-size: 17px;
    }
    @media only screen and (max-width: 731px) {
        font-size: 16px;
    }
`

const StyledProjectTitleCaption = styled(StyledCarouselCaption)`
    min-height: 66px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 10px 10px 0px 0px;
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: calc(60vw);
    max-width: 600px;
    background: black;
    border-bottom: 1px white solid;
`

const StyledProjectLinksContainer = styled('div')`
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content; center;
`

const StyledProjectLinksSuperContainer = styled(StyledProjectLinksContainer)`
    flex-wrap: wrap;
`

const LinkWrapper = styled('div')`
    color: #0000FF;
    text-decoration: underline;
    background-color: rgba(255, 255, 255, 0.7);
    margin: 4px;
    padding-left: 4px;
    padding-right: 4px;
`

const StyledDivider = styled(Divider)`
    width: 95%;
`

const ProjectsSection = ({ innerRef }) => {
    const [isProjectAutoPlayEnabled, setProjectAutoPlayEnabled] = useState(true)
    const [currentIndex, setCurrentIndex] = useState(0)
    
    const isBtMd = useMediaQuery(theme.breakpoints.up('md'))
    const passedMaxWidth = useMediaQuery('(min-width:1000px)')
    const showFeaturedInTitle = useMediaQuery('(min-width:500px)')
    const pictureWidth = passedMaxWidth ? "600px" : "calc(60vw)"
    const pictureHieght = passedMaxWidth ? "450px" : "calc(60vw * 0.75)"

    var projectTitle = get(PROJECTS, `${currentIndex}.title`)

    const carouselSectionRef = useRef()
    const isVisible = useOnScreen(carouselSectionRef)

    useEffect(() => {
        ReactGA.event({
            category: "Project Carousel",
            action: "Displayed",
            label: get(PROJECTS, `${currentIndex}.title`, 'Unkown'),
            nonInteraction: true,
          })
    }, [currentIndex])

    return (
        <div ref={innerRef}>
            <RevealingLightSection pageview="/projects">
                <DarkHeader> {showFeaturedInTitle && "Featured"} Projects & Contributions</DarkHeader>
                <StyledCarouselSectionContainer ref={carouselSectionRef} isBtMd={isBtMd} style={{ alignItems: 'center', flexDirection: 'column'}}>
                        <StyledProjectTitleCaption>
                            {projectTitle}
                        </StyledProjectTitleCaption>
                        <StyledCarouselSection>
                            <CarouselAutoPlayControl isAutoPlayEnabled={isProjectAutoPlayEnabled} setAutoPlayEnabled={setProjectAutoPlayEnabled} />
                            <StyledCarousel
                                onChange={(index) => setCurrentIndex(index)}
                                autoPlay={isProjectAutoPlayEnabled && isVisible}
                                infiniteLoop={true}
                                interval={10000}
                                useKeyboardArrows={true}
                                width={pictureWidth}
                                showThumbs={false}
                                showStatus={false}
                                statusFormatter={(current) => get(PROJECTS, `${current-1}.title`, "")}
                                stopOnHover={false}
                                preventMovementUntilSwipeScrollTolerance={true}
                                swipeScrollTolerance={30}
                            >
                                {map(PROJECTS, (projectData, index) => {
                                var posterKey = get(projectData, 'poster')
                                return (
                                    <StyledCarouselPage key={`TV_SHOW_${posterKey}_${index}`}>
                                        <div style={{ height: pictureHieght, display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                                            {(index - currentIndex < CAROUSEL_LOAD_DISTANCE) &&
                                                <Img
                                                    loader={<Loader loadingDarkMode={true} width={pictureWidth} height={pictureHieght} />}
                                                    unloader={<Alert severity="error">Could not load the {projectTitle} poster</Alert>}
                                                    alt={projectTitle}
                                                    width={pictureWidth}
                                                    height={pictureHieght}
                                                    src={getProjectPosterUrls(posterKey)}
                                                    style={{ width: pictureWidth, height: pictureHieght }}
                                                />
                                            }
                                        </div>
                                    </StyledCarouselPage>
                                )
                                })}
                            </StyledCarousel>
                        </StyledCarouselSection>
                        <StyledDivider>
                            Description
                        </StyledDivider>
                        <StyledProjectDescriptionCaption>
                            {get(PROJECTS, `${currentIndex}.description`)}
                            <StyledProjectLinksSuperContainer>
                                {
                                    map(get(PROJECTS, `${currentIndex}.links`), (link, subIndex) => (
                                        <StyledProjectLinksContainer key={`projectData_links_${currentIndex}_${subIndex}`}>
                                            {!!subIndex && " or "}
                                            <LinkWrapper>
                                                <a href={get(link, 'url')} target="_blank" rel="noreferrer">
                                                    {get(link, 'text')}
                                                </a>
                                            </LinkWrapper>
                                        </StyledProjectLinksContainer>
                                    ))
                                }
                            </StyledProjectLinksSuperContainer>
                        </StyledProjectDescriptionCaption>
                    </StyledCarouselSectionContainer>
            </RevealingLightSection>
        </div>
    )
}

export default ProjectsSection