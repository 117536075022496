import { get, map } from 'lodash'
import { hostUrls } from '../config/settings'

export const QUOTE_PICTURES_PATH = `quote_portraits`
export const QUOTE_AUTHORS = {
    "troy": {
        "portrait": "troy.png",
        "name": "Troy Barnes"
    },
    "abed": {
        "portrait": "abed.png",
        "name": "Abed Nadir"
    },
    "jeff": {
        "portrait": "jeff.png",
        "name": "Jeff Winger"
    },
    "annie": {
        "portrait": "annie.png",
        "name": "Annie Edison"
    },
    "pierce": {
        "portrait": "pierce.png",
        "name": "Pirece Hawthorne"
    },
    "dean": {
        "portrait": "dean.png",
        "name": "Craig Pelton"
    },
    "duncan": {
        "portrait": "duncan.png",
        "name": "Ian Duncan"
    },
    "chang": {
        "portrait": "chang.png",
        "name": "Ben Chang"
    },
    "shirley": {
        "portrait": "shirley.png",
        "name": "Shirley Bennett"
    },
    "britta": {
        "portrait": "britta.png",
        "name": "Britta Perry"
    },
    "starburns": {
        "name": "Alex \"Star-Burns\" Osbourne",
        "portrait": "starburns.png"
    },
    "leonard": {
        "name": "Leonard Rodriguez",
        "portrait": "leonard.png"
    },
    "michael": {
        "name": "Michael Scott",
        "portrait": "michael.png"
    },
    "prisonmike": {
        "name": "Prison Mike",
        "portrait": "prisonmike.png"
    },
    "dwight": {
        "name": "Dwight Shrute",
        "portrait": "dwight.png"
    },
    "buzz": {
        "name": "Buzz Hickey",
        "portrait": "buzz.png"
    },
    "creed": {
        "name": "Creed Bratton",
        "portrait": "creed.png"
    },
    "pam": {
        "name": "Pam Beesly",
        "portrait": "pam.png"
    },
    "jim": {
        "name": "Jim Halpert",
        "portrait": "jim.png"
    },
    "andy": {
        "name": "Andy Bernard",
        "portrait": "andy.png"
    },
    "erin": {
        "name": "Erin Hannon",
        "portrait": "erin.png"
    },
    "kelly": {
        "name": "Kelly Kapoor",
        "portrait": "kelly.png"
    },
    "ryan": {
        "name": "Ryan Howard",
        "portrait": "ryan.png"
    },
    "stanly": {
        "name": "Stanley Hudson",
        "portrait": "stanly.png"
    },
    "kevin": {
        "name": "Kevin Malone",
        "portrait": "kevin.png"
    },
    "jan": {
        "name": "Jan Levinson",
        "portrait": "jan.png"
    },
    "gavin": {
        "name": "Gavin Belson",
        "portrait": "gavin.png"
    },
    "russ": {
        "name": "Russ Hanneman",
        "portrait": "russ.png"
    },
    "richard": {
        "name": "Richard Hendricks",
        "portrait": "richard.png"
    },
    "gilfoyle": {
        "name": "Bertram Gilfoyle",
        "portrait": "gilfoyle.png"
    },
    "denish": {
        "name": "Dinesh Chugtai",
        "portrait": "denish.png"
    },
    "erlich": {
        "name": "Erlich Bachman",
        "portrait": "erlich.png"
    },
    "jian": {
        "name": "Jian Yang",
        "portrait": "jian.png"
    },
    "pete": {
        "name": "Pete Monahan",
        "portrait": "pete.png"
    },
    "jared": {
        "name": "Jared Dunn",
        "portrait": "jared.png"
    },
    "peter": {
        "name": "Peter Gregory",
        "portrait": "peter.png"
    },
    "ron": {
        "name": "Ron LaFlamme",
        "portrait": "ron.png"
    }
}

export const getAuthorNameByKey = (authorKey) => get(QUOTE_AUTHORS, `${authorKey}.name`, 'UNKNOWN')
export const getAuthorImageByKey = (authorKey) => `${QUOTE_PICTURES_PATH}/${get(QUOTE_AUTHORS, `${authorKey}.portrait`, 'unknown.png')}`
export const getAuthorPortraitUrls = (authorKey) => map(hostUrls, (url) => `${url}/${getAuthorImageByKey(authorKey)}`)

export const QUOTES = [
    {
        "quote": "Don't eat the crab dip, YA YA!",
        "authorKey": "troy"
    },
    {
        "quote": "And I knew exactly what to do. But in a much more real sense, I had no idea what to do.",
        "authorKey": "michael"
    },
    {
        "quote": "Failure is growth. Failure is learning. But sometimes failure is just failure. I think...",
        "authorKey": "gavin"
    },
    {
        "quote": "When you really know who you are and what you like about yourself, changing for other people isn't such a big deal.",
        "authorKey": "abed"
    },
    {
        "quote": "The worst thing about prison was the dementors.",
        "authorKey": "prisonmike"
    },
    {
        "quote": "I don't have an ego. My Facebook photo is a landscape.",
        "authorKey": "jeff"
    },
    {
        "quote": "You only live once? False. You live every day. You only die once.",
        "authorKey": "dwight"
    },
    {
        "quote": "I Was Never One To Hold A Grudge, Jeffrey. My Father Held Grudges. I'll Always Hate Him For That..",
        "authorKey": "pierce"
    },
    {
        "quote": "Your last blow-off class taught me to live in the moment which I will always regret and never do again.",
        "authorKey": "annie"
    },
    {
        "quote": "I mean, that Ken doll probably thinks traversing a binary search tree runs in the order of \"n,\" instead of \"log n.\" Pffft.. Idiot.",
        "authorKey": "richard"
    },
    {
        "quote": "Makes me feel like I’ve died and gone to hell.",
        "authorKey": "gilfoyle"
    },
    {
        "quote": "Don't insult me. I can fail circles around you losers.",
        "authorKey": "denish"
    },
    {
        "quote": "I know what a metaphor is! It's like a thought with another thought's hat on.",
        "authorKey": "britta"
    },
    {
        "quote": "Oh, Britta's in this?",
        "authorKey": "dean"
    },
    {
        "quote": "I'll imaginary sleep when I'm imaginary dead.",
        "authorKey": "buzz"
    },
    {
        "quote": "Kind people are always kind, not just when it's easy.",
        "authorKey": "shirley"
    },
    {
        "quote": "My self-published novel isn’t going to publish itself!",
        "authorKey": "duncan"
    },
    {
        "quote": "Shut your pompous vortex of overlapping fangs!",
        "authorKey": "chang"
    },
    {
        "quote": "Oh, you’re paying way too much for worms. Who’s your worm guy?",
        "authorKey": "creed"
    },
    {
        "quote": "I memorized the hexadecimal times tables when I was 14 writing machine code, okay? Ask me what 9 times F is. It's fleventy-five.",
        "authorKey": "erlich"
    },
    {
        "quote": "There’s a lot of beauty in ordinary things. Isn’t that kind of the point?",
        "authorKey": "pam"
    },
    {
        "quote": "Bears, beets, Battlestar Galactica.",
        "authorKey": "jim"
    },
    {
        "quote": "I wish there was a way to know you're in the good old days before you've actually left them.",
        "authorKey": "andy"
    },
    {
        "quote": "Erlich, he started crying in Taco Bell. He tried to blame the taco sauce.",
        "authorKey": "jian"
    },
    {
        "quote": "Next thing I knew, I was 70 miles away, wrapped naked in a blanket, shaking off a meth high, and facing charges for attacking a police horse with a shovel.",
        "authorKey": "pete"
    },
    {
        "quote": "Disposable cameras are fun although it does seem wasteful and you don't ever get to see your pictures.",
        "authorKey": "erin"
    },
    {
        "quote": "I talk a lot, so I’ve learned to tune myself out.",
        "authorKey": "kelly"
    },
    {
        "quote": "No. I haven't slept in a box in years.",
        "authorKey": "jared"
    },
    {
        "quote": "No such thing as bad press.",
        "authorKey": "leonard"
    },
    {
        "quote": "I fear a political career could shine a negative light on my drug dealing",
        "authorKey": "starburns"
    },
    {
        "quote": "How's my favorite branch doing!",
        "authorKey": "ryan"
    },
    {
        "quote": "Tres Comas Tequila!",
        "authorKey": "russ"
    },
    {
        "quote": "The doctor said, if I can't find a new way to relate more positively to my surroundings, I'm going to die. ...I'm going to die.",
        "authorKey": "stanly"
    },
    {
        "quote": "The trick is to under-cook the onions. Everybody is going to get to know each other in the pot.",
        "authorKey": "kevin"
    },
    {
        "quote": "There are always a million reasons not to do something.",
        "authorKey": "jan"
    },
    {
        "quote": "Go work at Burger King. Go into the woods and forage for nuts and berries. Do not go back to college!",
        "authorKey": "peter"
    },
    {
        "quote": "No. It's ying and yang, they're opposites.",
        "authorKey": "ron"
    },
    {
        "quote": "Never change, or do. I'm not your boss.",
        "authorKey": "troy"
    },
    {
        "quote": "Would I rather be feared or loved? Easy. Both. I want people to be afraid of how much they love me.",
        "authorKey": "michael"
    },
    {
        "quote": "Securing my legacy with you at my wing. Wear pants you can kneel in.",
        "authorKey": "gavin"
    },
    {
        "quote": "Chaos already dominates enough of our lives. The universe is an endless raging sea of randomness. Our job isn't to fight it, but to weather it together, on the raft of life",
        "authorKey": "abed"
    },
    {
        "quote": "All right, hey, hey, hey, hey. That’s just the way we talk in the clink.",
        "authorKey": "prisonmike"
    },
    {
        "quote": "I settled on a truth today that is always going to be true: that I'll do anything for my friends.",
        "authorKey": "jeff"
    },
    {
        "quote": "Whenever I'm about to do something, I think, 'Would an idiot do that?' and if they would, I do not do that thing.",
        "authorKey": "dwight"
    },
    {
        "quote": "Streets ahead.",
        "authorKey": "pierce"
    },
    {
        "quote": "Webster's Dictionary defines? That's the Jim Belushi of speech openings. It accomplishes nothing, but everyone keeps using it and nobody knows why.",
        "authorKey": "annie"
    },
    {
        "quote": "I'm not hiring him. He uses spaces not tabs.",
        "authorKey": "richard"
    },
    {
        "quote": "I’m sure you can find your way out with one of your two faces.",
        "authorKey": "gilfoyle"
    },
    {
        "quote": "I'm gonna be honest with you. No offense to me, but I am greedy and unreliable, bordering on piece of s***",
        "authorKey": "denish"
    },
    {
        "quote": "If I Had No Self-Awareness, I Think I'd Know.",
        "authorKey": "britta"
    },
    {
        "quote": "Frankly, my dear, I don't give a dean!",
        "authorKey": "dean"
    },
    {
        "quote": "Millimeter don't make no difference. Bullets just kinda kill you.",
        "authorKey": "buzz"
    },
    {
        "quote": "I'll make your a** linear",
        "authorKey": "shirley"
    },
    {
        "quote": "So.. what is anthropology? Seriously, does anyone know?",
        "authorKey": "duncan"
    },
    {
        "quote": "Fire can't go through doors, stupid. It's not a ghost.",
        "authorKey": "chang"
    },
    {
        "quote": "If I can’t scuba, then what’s this all been about? What am I working toward?",
        "authorKey": "creed"
    },
    {
        "quote": "Don't touch anything. Failure is contagious.",
        "authorKey": "erlich"
    },
    {
        "quote": "And I feel God in this Chili’s tonight.",
        "authorKey": "pam"
    },
    {
        "quote": "Everything I have I owe to this job… this stupid, wonderful, boring, amazing job.",
        "authorKey": "jim"
    },
    {
        "quote": "I went to Cornell. You ever heard of it?",
        "authorKey": "andy"
    },
    {
        "quote": "Gilfoyle, you are racist. And Richard... you are ugly. The Errich administration is over.",
        "authorKey": "jian"
    },
    {
        "quote": "Was I in possession of cocaine, amphetamines, amyl nitrate, also known as poppers, at the time of my arrest? In large quantities.",
        "authorKey": "pete"
    },
    {
        "quote": "Who says exactly what they’re thinking? What kind of a game is that?",
        "authorKey": "kelly"
    },
    {
        "quote": "I don't know what to do. Should we do verbal SWOT analysis?",
        "authorKey": "jared"
    },
    {
        "quote": "Hey guys, thanks for eating all the macaroni!",
        "authorKey": "leonard"
    },
    {
        "quote": "A few years ago, my family was on a safari in Africa and my cousin, Mufasa, was um, he was trampled to death by a pack of wildebeests and um, we all took it really hard.",
        "authorKey": "ryan"
    },
    {
        "quote": "We are going to drink to re-billionizing.",
        "authorKey": "russ"
    },
    {
        "quote": "I wake up every morning in a bed that’s too small, drive my daughter to a school that’s too expensive, and then I go to work to a job for which I get paid too little. But on pretzel day? Well, I like pretzel day.",
        "authorKey": "stanly"
    },
    {
        "quote": "Mini-cupcakes? As in the mini version of regular cupcakes? Which is already a mini version of cake? Honestly, where does it end with you people?",
        "authorKey": "kevin"
    },
    {
        "quote": "Downside...I date Michael Scott publicly and collapse in on myself like a dying star.",
        "authorKey": "jan"
    },
    {
        "quote": "The true value of snake oil is intangible as well.",
        "authorKey": "peter"
    },
    {
        "quote": "Always tell me that and tell yourself that. Because if you believe it, a jury will, too.",
        "authorKey": "ron"
    },
    {
        "quote": "I give this year a D, for delightful!",
        "authorKey": "troy"
    },
    {
        "quote": "Do I need to be liked? Absolutely not. I like to be liked. I enjoy being liked. I have to be liked, but it’s not like this compulsive need to be liked, like my need to be praised.",
        "authorKey": "michael"
    },
    {
        "quote": "Editors are for people who can't write correctly the first time. We'll self-publish.",
        "authorKey": "gavin"
    },
    {
        "quote": "You can do whatevey you want, you just have to know what that is.",
        "authorKey": "abed"
    },
    {
        "quote": "And I nevah got caught, neither.",
        "authorKey": "prisonmike"
    },
    {
        "quote": "You are all better than you think you are, you are just designed not to believe it when you hear it from yourself.",
        "authorKey": "jeff"
    },
    {
        "quote": "Identity theft is not a joke, Jim!",
        "authorKey": "dwight"
    },
    {
        "quote": "Will somebody please call all the ambulances",
        "authorKey": "pierce"
    },
    {
        "quote": "I was so unpopular in high school, the crossing guards used to lure me into traffic.",
        "authorKey": "annie"
    },
    {
        "quote": "I always knew I was missing something, and then when someone explained the concept of \"game\". I remember very distinctly thinking, \"That's what I don't have\".",
        "authorKey": "richard"
    },
    {
        "quote": "I’m effectively leveraging your misery. I’m like the Warren Buffet of f***ing with you.",
        "authorKey": "gilfoyle"
    },
    {
        "quote": "I think we've established our ineptitude beyond a reasonable doubt.",
        "authorKey": "denish"
    },
    {
        "quote": "The perfect Jeff Winger blow-off class: a class that doesn't exist.",
        "authorKey": "britta"
    },
    {
        "quote": "Well I guess we don't see our patterns until they are laid out in front of us.",
        "authorKey": "dean"
    },
    {
        "quote": "If I come over there, there are gonna be two sounds. Me hitting you.. twice.",
        "authorKey": "buzz"
    },
    {
        "quote": "I'll make your a** sense.",
        "authorKey": "shirley"
    },
    {
        "quote": "All life ends in death, which we, as a species, are cursed with knowing. Resulting in…something. Again, this is really not my field.",
        "authorKey": "duncan"
    },
    {
        "quote": "I am a man who can never die.",
        "authorKey": "chang"
    },
    {
        "quote": "I run a small fake ID company from my car with a laminating machine that I swiped from the Sheriff’s station.",
        "authorKey": "creed"
    },
    {
        "quote": "One of you is one of the least attractive people I’ve ever met and I’m not going to say which one.",
        "authorKey": "erlich"
    },
    {
        "quote": "When you’re a kid, you assume your parents are soulmates. My kids are going to be right about that.",
        "authorKey": "pam"
    },
    {
        "quote": "Let the record show that Dwight K Schrute is now completely nude and is holding a plastic knife to Stanley’s neck",
        "authorKey": "jim"
    },
    {
        "quote": "I'm always thinking one step ahead, like a...carpenter...that makes stairs.",
        "authorKey": "andy"
    },
    {
        "quote": "Big mortgage. Seven credit cards. Not even one with miles. F***ing loser.",
        "authorKey": "jian"
    },
    {
        "quote": "I used to take a tampon, soak it in grain alcohol, and stick it up into my rectum. That got me high, Richard.",
        "authorKey": "pete"
    },
    {
        "quote": "What are your weaknesses?\" \"I don't have any, a**hole.",
        "authorKey": "kelly"
    },
    {
        "quote": "I've always wanted to be part of a suicide pact.",
        "authorKey": "jared"
    },
    {
        "quote": "I knew this day would come. I'm out of here.",
        "authorKey": "leonard"
    },
    {
        "quote": "people keep calling me a wunderkind.",
        "authorKey": "ryan"
    },
    {
        "quote": "Crazy Town's gonna be there. So is Puddle of Mudd. You know Puddle of Mudd?",
        "authorKey": "russ"
    },
    {
        "quote": "Boy, have you done lost your mind? Cause I’ll help you find it!",
        "authorKey": "stanly"
    },
    {
        "quote": "You think this is a great party? This cake has vegetables in it. Like a salad bar, Robert.",
        "authorKey": "kevin"
    },
    {
        "quote": "I don't even think I could say, \"Pied Piper is a proprietary site. \" Well, I just did but it wasn't easy.",
        "authorKey": "peter"
    },
    {
        "quote": "You can't use the term \"in exchange.\" It implies causality. That's basically extortion",
        "authorKey": "ron"
    },
    {
        "quote": "You can yell at me all you want! I've seen enough movies to know that popping the back of a raft makes it go faster!",
        "authorKey": "troy"
    },
    {
        "quote": "Sometimes I’ll start a sentence and I don’t even know where it’s going. I just hope I find it along the way, like an improv conversation....an improversation.",
        "authorKey": "michael"
    },
    {
        "quote": "If we can make your audio and video files smaller, we can make cancer smaller. And hunger. And... AIDS.",
        "authorKey": "gavin"
    },
    {
        "quote": "Six seasons and a movie!",
        "authorKey": "abed"
    },
    {
        "quote": "I'm prepared for any insane adventure life throws our way and I don't know about you, but I'm looking forward to every one of them.",
        "authorKey": "jeff"
    },
    {
        "quote": "In the end, the greatest snowball isn't a snowball at all. It's fear.",
        "authorKey": "dwight"
    },
    {
        "quote": "You gain levels, and at a certain point you actually CAN eat a ghost.",
        "authorKey": "pierce"
    },
    {
        "quote": "Put it in a letter, Jane Austen!",
        "authorKey": "annie"
    },
    {
        "quote": "Well, you're stealing it all wrong. I mean, this is how you're gonna build the code book? Honestly? This is bush-league. And look at all these redundancies.",
        "authorKey": "richard"
    },
    {
        "quote": "Exactly. \"Richard is great, but you know\"... R-I-G-B-Y.",
        "authorKey": "gilfoyle"
    },
    {
        "quote": "Rigby.",
        "authorKey": "denish"
    },
    {
        "quote": "Well I have a photography project to finish and my grandmother's hands aren't going to take close-ups of themselves. Hasta la later.",
        "authorKey": "britta"
    },
    {
        "quote": "Would that this hoodie were a time-hoodie!",
        "authorKey": "dean"
    },
    {
        "quote": "You know... … I could… … I fought for this country, and…I know you don't get to pick and choose which parts you fight for…but I know—",
        "authorKey": "buzz"
    },
    {
        "quote": "These are your loose ends, I’m just tying them up.",
        "authorKey": "shirley"
    },
    {
        "quote": "It's you, or me. And I'm me.",
        "authorKey": "duncan"
    },
    {
        "quote": "HAAAAAAAAAAAAAAAAAAAAAAAAAAAMMMMMMMM GIRRRRL",
        "authorKey": "chang"
    },
    {
        "quote": "I already won the lottery. I was born in the US of A, baby. And as backup, I have a Swiss passport.",
        "authorKey": "creed"
    },
    {
        "quote": "No, no, no. Richard, Richard, Richard, Richard. Respectfully… respectfully, you’re terrible.",
        "authorKey": "erlich"
    },
    {
        "quote": "I don’t care what they say about me. I just want to eat. Which I realize is a lot to ask for…at a dinner party.",
        "authorKey": "pam"
    },
    {
        "quote": "How dare you? My family built this country, by the way",
        "authorKey": "jim"
    },
    {
        "quote": "Sorry I annoyed you with my friendship.",
        "authorKey": "andy"
    },
    {
        "quote": "Richard, congratulations. It's your very close friend Jian-Yang, and I would like you to give me free shares",
        "authorKey": "jian"
    },
    {
        "quote": "Okay. I would red-line it, but... I'm not allowed to have a pencil.",
        "authorKey": "pete"
    },
    {
        "quote": "What if I threaten you guys? Just hear me out…f***ing pigs. I-I hope that you get cheated out of your overtime.",
        "authorKey": "jared"
    },
    {
        "quote": "Feel the force. What? I don't feel the force. I'm not allowed to. What do I feel? I feel Dracula force.",
        "authorKey": "leonard"
    },
    {
        "quote": "Sometimes, it's about wisdom. And did I lose a B? Close to it. F***! But I bet I gained two B in wisdom.",
        "authorKey": "russ"
    },
    {
        "quote": "i have very little patience for stupidity.",
        "authorKey": "kevin"
    },
    {
        "quote": "Have any of you ever eaten at \"Burger King\"?",
        "authorKey": "peter"
    },
    {
        "quote": "So, in effect, you kind of just threatened to sue me, and I don't take kindly to threats, Mr. Hendricks.",
        "authorKey": "ron"
    },
    {
        "quote": "I AM THE TRUEST REPAIRMAN!",
        "authorKey": "troy"
    },
    {
        "quote": "I. DECLARE. BANKRUPTCY!",
        "authorKey": "michael"
    },
    {
        "quote": "It's kombucha with ginseng and lemongrass, Richard. I'm not a f***ing hobo.",
        "authorKey": "gavin"
    },
    {
        "quote": "I Guess I Just Like Liking Things.",
        "authorKey": "abed"
    },
    {
        "quote": "None of us have to go to anyone, and the idea we do is a mental illness we contracted from breath mint commercials and Sandra Bullock.",
        "authorKey": "jeff"
    },
    {
        "quote": "You couldn't handle my undivided attention.",
        "authorKey": "dwight"
    },
    {
        "quote": "if I ever let being bad at something stop me, I wouldn't be here. That thing some men call 'failure,' I call 'living.' 'Breakfast.' And I'm not leaving until I've cleaned out the buffet.",
        "authorKey": "pierce"
    },
    {
        "quote": "Maybe I'll blow off talking language. Blee bloo blah blah blah blee bloo bluh bluuhhhh.",
        "authorKey": "annie"
    },
    {
        "quote": "Jobs was a poser. He didn’t even write code.",
        "authorKey": "richard"
    },
    {
        "quote": "he's as pointless as Mass Effect 3's multiple endings. I mean, he's a completely useless appendage and we all know it.",
        "authorKey": "gilfoyle"
    },
    {
        "quote": "I was CEO for 11 days and in that time, and racked up fines the size of a small nation’s GDP.",
        "authorKey": "denish"
    },
    {
        "quote": "Blaming a bridge collapse on a school is like blaming owls for why I suck at analogies.",
        "authorKey": "britta"
    },
    {
        "quote": "According to Dean Law, I must now give her my amulet.",
        "authorKey": "dean"
    },
    {
        "quote": "Publishers are interested!",
        "authorKey": "buzz"
    },
    {
        "quote": "Yeah I used to love dying but that speech really turned me around.",
        "authorKey": "shirley"
    },
    {
        "quote": "That's funny, because most people would have a bit more trouble saying booyah to moral relativism.",
        "authorKey": "duncan"
    },
    {
        "quote": "The BRENDA-PARADOX!",
        "authorKey": "chang"
    },
    {
        "quote": "I wanna do a cartwheel. But real casual-like. Not enough to make a big deal out of it, but I know everyone saw it. One stunning, gorgeous cartwheel.",
        "authorKey": "creed"
    },
    {
        "quote": "We'll call you when we want pleated khakis.",
        "authorKey": "erlich"
    },
    {
        "quote": "If it were an iPod, it would be a shuffle.",
        "authorKey": "pam"
    },
    {
        "quote": "Uh, this Wallace guy is lookin' at hard time. And we only know this because of what Dwight Snoot said on record.",
        "authorKey": "jim"
    },
    {
        "quote": "You give me a gift? Bam! Thank You note. You invite me somewhere? Pow! RSVP. You do me a favor? Wham! Favor returned. Do not test my politeness.",
        "authorKey": "andy"
    },
    {
        "quote": "Erlich Bachman, this is you as an old man. I'm ugly and I'm dead. Alone.",
        "authorKey": "jian"
    },
    {
        "quote": "I like this new angry side to you. Being around angry people relaxes me, because I know where I stand.",
        "authorKey": "jared"
    },
    {
        "quote": "ROI. ROI. You know what that stands for? Radio On the Internet",
        "authorKey": "russ"
    },
    {
        "quote": "I was never enjoying it. I only eat it for the nutrients.",
        "authorKey": "peter"
    },
    {
        "quote": "It was awesome, but also.. it wasn't?",
        "authorKey": "troy"
    },
    {
        "quote": "I love inside jokes. I’d love to be a part of one someday.",
        "authorKey": "michael"
    },
    {
        "quote": "Well, if you could you would, but you can't. You're stuck with me, and I'm stuck with you.",
        "authorKey": "gavin"
    },
    {
        "quote": "Sometimes the hardest prisons to break out of, are the ones without locks.",
        "authorKey": "abed"
    },
    {
        "quote": "Stop making our hatred of ourselves someone else's job, and just stop hating ourselves.",
        "authorKey": "jeff"
    },
    {
        "quote": "I overslept. Damn rooster didn't crow.",
        "authorKey": "dwight"
    },
    {
        "quote": "I'm confused.. When do we get our ice cream?",
        "authorKey": "pierce"
    },
    {
        "quote": "They can advertise things without you knowing? Is that legal? It sounds like brain Windexing.",
        "authorKey": "annie"
    },
    {
        "quote": "Not anymore. Our network just blew it apart like a prolapsed anus.",
        "authorKey": "richard"
    },
    {
        "quote": "Why don't you go inside and write some princess code? Leave the hardware up to us servants.",
        "authorKey": "gilfoyle"
    },
    {
        "quote": "\"PlaceHolder\" would honestly be a better name than Pied Piper.",
        "authorKey": "denish"
    },
    {
        "quote": "Britta for the win! Whoo whooooaa",
        "authorKey": "britta"
    },
    {
        "quote": "No matter what happens I'm with you! But don't tell anyone I'm with you, if you do I'll deny it.",
        "authorKey": "dean"
    },
    {
        "quote": "...ooohh let's dress in pajamas and play pillow fort in the middle of the day I WATCHED MY THIRD WIFE DIE.",
        "authorKey": "buzz"
    },
    {
        "quote": "You tell Buzz Hickey that Shirley Bennett said... well, I don't want to waste your time. Just think of something cool and give me credit.",
        "authorKey": "shirley"
    },
    {
        "quote": "Oh no, my shoe is untied by British standards.",
        "authorKey": "duncan"
    },
    {
        "quote": "It’s a palomino",
        "authorKey": "chang"
    },
    {
        "quote": "Creed Bratton has never declared bankruptcy. When Creed Bratton gets in trouble, he transfers his debt to William Charles Scheider.",
        "authorKey": "creed"
    },
    {
        "quote": "Time is a sphere, and I have been reincarnated during the same time period in which I exist.",
        "authorKey": "erlich"
    },
    {
        "quote": "I’ll send out an APB... also known as an Ask Pam Beesly.",
        "authorKey": "pam"
    },
    {
        "quote": "Take a different way home from work, man!",
        "authorKey": "jim"
    },
    {
        "quote": "In high school, I organized a walk out over standardized testing. Got over 500 students to just skip the SATs. At the last second I chickened out",
        "authorKey": "andy"
    },
    {
        "quote": "I was driving next to a store window, and I stop and look in my reflection. I looked like an a**hole.",
        "authorKey": "jian"
    },
    {
        "quote": "It says here on your resume that from 2010 to 2011 you \"crushed it\"?",
        "authorKey": "jared"
    },
    {
        "quote": "22 years young and I'm worth 1.2 billion. Now a couple decades later, I'm worth 1.4. You do the math.",
        "authorKey": "russ"
    },
    {
        "quote": "I don't think I could write a song.",
        "authorKey": "peter"
    },
    {
        "quote": "There is a time and place for subtlety, and that time was before Scary Movie.",
        "authorKey": "troy"
    },
    {
        "quote": "It just seems awfully mean. But sometimes, the ends justify the mean.",
        "authorKey": "michael"
    },
    {
        "quote": "...and Hooli, sir. Hooli also acknowledged some spectacular lapses.",
        "authorKey": "gavin"
    },
    {
        "quote": "\"The night beckons. Its black fingers curl and uncurl, going like, 'Hey, come here.'\" - Batman",
        "authorKey": "abed"
    },
    {
        "quote": "I discovered at a very early age that if I talked long enough, I could make anything right or wrong. So either I'm god, or truth is relative. Either way: Booyah.",
        "authorKey": "jeff"
    },
    {
        "quote": "I don't believe you, continue.",
        "authorKey": "dwight"
    },
    {
        "quote": "Nice going, tweedledumb and ever tweedlerdumb!",
        "authorKey": "pierce"
    },
    {
        "quote": "You’re on your own, Al Capone.",
        "authorKey": "annie"
    },
    {
        "quote": "It's weird. I actually don't know what to do when things are going well. It is not natural.",
        "authorKey": "richard"
    },
    {
        "quote": "My servers can handle 10 times the traffic if they weren't busy apologizing for your shit codebase.",
        "authorKey": "gilfoyle"
    },
    {
        "quote": "Inferior products win out all the time.",
        "authorKey": "denish"
    },
    {
        "quote": "Would you like a patent on getting your a** kicked?",
        "authorKey": "britta"
    },
    {
        "quote": "Guys, Greendale's music department is flat \"baroque,\" so we are having a fund-raiser.",
        "authorKey": "dean"
    },
    {
        "quote": "I wake up every night screaming!",
        "authorKey": "buzz"
    },
    {
        "quote": "I'm just not a fan of movies about movies about people making movies, like damn Charlie Kaufman some of us have work in the morning",
        "authorKey": "shirley"
    },
    {
        "quote": "My name is Ian Duncan and I'm here to say, I'm going to rap to the beat in a rapping way!",
        "authorKey": "duncan"
    },
    {
        "quote": "It's a prescription throne.",
        "authorKey": "chang"
    },
    {
        "quote": "When Pam gets Michael’s old chair, I get Pam’s old chair. Then I’ll have two chairs. Only one to go.",
        "authorKey": "creed"
    },
    {
        "quote": "Once again, because of your poor managerial skills, I've been hoisted upon my own petard.",
        "authorKey": "erlich"
    },
    {
        "quote": "One thing I learned from watching world poker tour at 2 in the morning: you play the opponent, not the cards.",
        "authorKey": "pam"
    },
    {
        "quote": "I am Bill Buttlicker.",
        "authorKey": "jim"
    },
    {
        "quote": "You should be an English professor at Cor-not University.",
        "authorKey": "andy"
    },
    {
        "quote": "No. There's no special occasion ever happen in your house.",
        "authorKey": "jian"
    },
    {
        "quote": "No one's heard of Meinertzhagen's haversack?",
        "authorKey": "jared"
    },
    {
        "quote": "Question. What's got two thumbs and three commas? This guy.",
        "authorKey": "russ"
    },
    {
        "quote": "Sometimes I think I lost something really important to me, and then it turns out I already ate it.",
        "authorKey": "troy"
    },
    {
        "quote": "I am Beyonce, always.",
        "authorKey": "michael"
    },
    {
        "quote": "Tim Cook keeps inviting me to his brunches. F*** him! I'll host the f***ing brunches.",
        "authorKey": "gavin"
    },
    {
        "quote": "\"I like football.. but also.. I don't?\" - Troy Barnes",
        "authorKey": "abed"
    },
    {
        "quote": "Look at me. It’s clear to you that I am awesome, but I can never admit that because that would make me an a**",
        "authorKey": "jeff"
    },
    {
        "quote": "Power points are the peacocks of the business world; all show, no meat.",
        "authorKey": "dwight"
    },
    {
        "quote": "I'm watching you two 24/2010.",
        "authorKey": "pierce"
    },
    {
        "quote": "Nice try Mike Ty....son.",
        "authorKey": "annie"
    },
    {
        "quote": "it's minimalist, you know? Sharp, clean lines.",
        "authorKey": "richard"
    },
    {
        "quote": "Outed by Wi-Fi.",
        "authorKey": "gilfoyle"
    },
    {
        "quote": "I don't wanna sound selfish, but sometimes I wish only I made money. You know?",
        "authorKey": "denish"
    },
    {
        "quote": "Uh, I Lived In New York, Troy, I Know What A Baggel Is.",
        "authorKey": "britta"
    },
    {
        "quote": "Sweet Deans.",
        "authorKey": "dean"
    },
    {
        "quote": "I would raise goats, hoard cinnamon, and travel only by night.",
        "authorKey": "buzz"
    },
    {
        "quote": "Oooooh! That’s niiice.",
        "authorKey": "shirley"
    },
    {
        "quote": "Now this is why I came to America",
        "authorKey": "duncan"
    },
    {
        "quote": "Bear down for midterms...",
        "authorKey": "chang"
    },
    {
        "quote": "Making the world a better place. Making the world a better place. Making the world a better place...",
        "authorKey": "erlich"
    },
    {
        "quote": "Oh, my god, Dwight's kind of my friend.",
        "authorKey": "pam"
    },
    {
        "quote": "It's better to be at the bottom of a ladder you want to climb then halfway up one you don't.",
        "authorKey": "jim"
    },
    {
        "quote": "A word of advice, speaking as a former baby. Don't get too hung up on baby names.",
        "authorKey": "andy"
    },
    {
        "quote": "Question for you. What's better than octopus recipe? Answer for you. Eight recipes for octopus.",
        "authorKey": "jian"
    },
    {
        "quote": "I have a lifelong aversion to my own image. You know, it’s like my foster mother used to say, “Donald, you have a face for the closet.",
        "authorKey": "jared"
    },
    {
        "quote": "I think we need to talk about getting some shwaaaaaaaaag.",
        "authorKey": "russ"
    },
    {
        "quote": "It said \"Market Price\". WHAT MARKET ARE YOU SHOPPING AT?!",
        "authorKey": "troy"
    },
    {
        "quote": "I understand nothing.",
        "authorKey": "michael"
    },
    {
        "quote": "This is supposed to be my signature. On what planet does a signature have a signature on it?",
        "authorKey": "gavin"
    },
    {
        "quote": "This is the darkest timeline.",
        "authorKey": "abed"
    },
    {
        "quote": "Oh I'm serious baby, I'm yahoo! serious. I'm serious FM. Welcome to the world series of seriousness sponsored by honey nut seri-Os.",
        "authorKey": "jeff"
    },
    {
        "quote": "I’m fast. To give you a reference point. I’m somewhere between a snake and a mongoose. And a panther.",
        "authorKey": "dwight"
    },
    {
        "quote": "Oh no, you're not letting some slumlord take your hard earned money. I'll fix it. I used to do that kind of thing all the time when I was a slumlord.",
        "authorKey": "pierce"
    },
    {
        "quote": "here's the deal, jessica biel.",
        "authorKey": "annie"
    },
    {
        "quote": "Oh? And what was that? Invent the MOSFET transistor? (chuckles) It's old technology. It's very old, like you.",
        "authorKey": "richard"
    },
    {
        "quote": "It has all of that going for it, Richard, and I still hate it.",
        "authorKey": "gilfoyle"
    },
    {
        "quote": "That was an out-of-body experience. It was like God was coding through me. Time stood still.",
        "authorKey": "denish"
    },
    {
        "quote": "If Loving Worms Is Stupid, I Don't Wanna Be Smart!",
        "authorKey": "britta"
    },
    {
        "quote": "As you know, our student records are stored on a Microsoft Paint file, which I was assured was future-proof. Meanwhile, our extended warranty is not being honored by the restaurant that used to be Circuit City.",
        "authorKey": "dean"
    },
    {
        "quote": "Mark Zuckerberg is Fidel Castro in flip-flops.",
        "authorKey": "buzz"
    },
    {
        "quote": "That's where you have to go. No more thought, no more feeling. Kill or be killed. Kill or be killed, little man.",
        "authorKey": "shirley"
    },
    {
        "quote": "Sorry I'm late. The sidewalk is more comfortable than it looks.",
        "authorKey": "duncan"
    },
    {
        "quote": "DID SOMEONE SAY CRAZY PERSON!?",
        "authorKey": "chang"
    },
    {
        "quote": "My head is so far up my own a** I can see the future.",
        "authorKey": "erlich"
    },
    {
        "quote": "I did the coal walk Michael, you couldn't even do that. Maybe I should be your boss.",
        "authorKey": "pam"
    },
    {
        "quote": "You are everything.",
        "authorKey": "jim"
    },
    {
        "quote": "I know a few things about love. Horrible, terrible, awful, awful things.",
        "authorKey": "andy"
    },
    {
        "quote": "I'm going to smoke in my room. Special occasion.",
        "authorKey": "jian"
    },
    {
        "quote": "I’ve never felt like I was anyone’s bro before.",
        "authorKey": "jared"
    },
    {
        "quote": "I'm out of the Three Comma Club. Functionally, I'm just like you. Jesus, f***, that's depressing.",
        "authorKey": "russ"
    },
    {
        "quote": "We are 40 light years outside of the buttermilk nebula, although it is possible ... yeah, it's a sticker?",
        "authorKey": "troy"
    },
    {
        "quote": "An office is a place where dreams come true.",
        "authorKey": "michael"
    },
    {
        "quote": "My cholesterol is through the roof. At this rate I'll be dead by the age of 120.",
        "authorKey": "gavin"
    },
    {
        "quote": "Cool. Cool, cool, cool.",
        "authorKey": "abed"
    },
    {
        "quote": "I love that I got to be with you guys. You saved my life, and changed it forever.",
        "authorKey": "jeff"
    },
    {
        "quote": "Today, smoking is going to save lives.",
        "authorKey": "dwight"
    },
    {
        "quote": "How long was I out? Is Napster still a thing?",
        "authorKey": "pierce"
    },
    {
        "quote": "I’m gonna, Greg Muldunna!",
        "authorKey": "annie"
    },
    {
        "quote": "Petty? No, I was gonna say \"illustrative of our vast differences.\"",
        "authorKey": "richard"
    },
    {
        "quote": "This just became a job.",
        "authorKey": "gilfoyle"
    },
    {
        "quote": "License to kill-9. IB action-dot-erotica.",
        "authorKey": "denish"
    },
    {
        "quote": "I Don't Start Watching Shows Until They're So Popular That Watching Them Is No Longer A Statement.",
        "authorKey": "britta"
    },
    {
        "quote": "This better not awaken anything in me.",
        "authorKey": "dean"
    },
    {
        "quote": "I was in the storm drains of the black river ripper, I have seen human heads seen used for things other than heads! So you best not be calling me a monster!",
        "authorKey": "buzz"
    },
    {
        "quote": "Leonard you better back that pumpkin a** up, or I will make a pie.",
        "authorKey": "shirley"
    },
    {
        "quote": "However angry he is, I am one tenth as angry. Because one of the 10 million would have been mine, because I own 10%...",
        "authorKey": "erlich"
    },
    {
        "quote": "You can find your broom and fly away.",
        "authorKey": "jian"
    },
    {
        "quote": "I think we need to define our corporate culture.",
        "authorKey": "jared"
    },
    {
        "quote": "Who gives a f***? That has doors that open like this. Not like this. Or like this. So it's all f***ed.",
        "authorKey": "russ"
    },
    {
        "quote": "So plastic menus are bad? See, this is the kind of stuff I need to learn.",
        "authorKey": "troy"
    },
    {
        "quote": "Tell him to call me ASAP as possible.",
        "authorKey": "michael"
    },
    {
        "quote": "Oh. The bear is sticky with honey. See ya soon.",
        "authorKey": "gavin"
    },
    {
        "quote": "Well anyone can be a lawyer. You can even represent yourself. You can't do surgery on yourself, it's illegal. You'd get arrested. And then you get a free lawyer",
        "authorKey": "abed"
    },
    {
        "quote": "Why are we in such a rush to leave the tidepool when the only things waiting for us on shore are the sands of time and the hungry seagulls of slowly growing apart?",
        "authorKey": "jeff"
    },
    {
        "quote": "I am faster than 80 percent of all snakes.",
        "authorKey": "dwight"
    },
    {
        "quote": "hey Hey HEY — Christina Reach-y",
        "authorKey": "annie"
    },
    {
        "quote": "You know, all the guys at the house, they think I'm nuts, but I'm not nuts.",
        "authorKey": "richard"
    },
    {
        "quote": "All right. As long as God isn't involved and there's beer.",
        "authorKey": "gilfoyle"
    },
    {
        "quote": "This is a video of maximizing alphaness.",
        "authorKey": "denish"
    },
    {
        "quote": "We Know That He Hates Money, Or Loves It, Or Doesn't Care About Money, And Hates Butts, Or Loves Them.",
        "authorKey": "britta"
    },
    {
        "quote": "What's wrong Annie? You came out of the bedroom smiling and then your smile faded as you leaned against the door?",
        "authorKey": "dean"
    },
    {
        "quote": "Oh no, someone showed Abed a sandwich and now we're all traveling through time.",
        "authorKey": "buzz"
    },
    {
        "quote": "If I was in 70 films over 30 years, and spent each one talking at random volumes, I might accidentally win an Oscar.",
        "authorKey": "shirley"
    },
    {
        "quote": "I'm already smiling, do you really have to paint me giving the thumbs-up? It's gratuitous.",
        "authorKey": "erlich"
    },
    {
        "quote": "Who let her in? I take one lousy s***...",
        "authorKey": "jian"
    },
    {
        "quote": "I ought to knock your teeth, you bitch-made motherf***er. …I was state-raised! You think I’m scared to catch a case, on some bulls***?",
        "authorKey": "jared"
    },
    {
        "quote": "Sh*t, no. I got three nannies suing me right now, one of them for no reason.",
        "authorKey": "russ"
    },
    {
        "quote": "Plastic menus seem like a great idea to me, but when you spill something on them and your mom says 'Hey Troy you're ruining Fuddruckers for everyone.'",
        "authorKey": "troy"
    },
    {
        "quote": "I say dance, they say, ‘How high?'",
        "authorKey": "michael"
    },
    {
        "quote": "I'm drawing thoughtful, zoological comparisons.",
        "authorKey": "gavin"
    },
    {
        "quote": "So much Jimming…",
        "authorKey": "abed"
    },
    {
        "quote": "TV’s the best dad there is. TV never came home drunk, TV never forgot me at the zoo, TV never abused and insulted me... unless you count Cop Rock.",
        "authorKey": "jeff"
    },
    {
        "quote": "Face it, Jared, being too early is the same as being wrong.",
        "authorKey": "richard"
    },
    {
        "quote": "At least it didn’t happen in a public and brutally embarrassing way.",
        "authorKey": "gilfoyle"
    },
    {
        "quote": "I lied to my cousin's face. He is going to lose $40 million, give or take, plus his estate in Hawaii... I hope.",
        "authorKey": "denish"
    },
    {
        "quote": "Well I’m a peanut bar and I’m here to say Your checks will arrive on another day! Another day, another dime, another rhyme, another dollar Another stuffed shirt with another white collar",
        "authorKey": "dean"
    },
    {
        "quote": "I'm offended by that question.",
        "authorKey": "buzz"
    },
    {
        "quote": "Smokers. Because, let's face it, no one's ever died of secondhand heroin.",
        "authorKey": "erlich"
    },
    {
        "quote": "You are from Errich's administration.",
        "authorKey": "jian"
    },
    {
        "quote": "you gave me hope and you gave me a sense of self-worth. Like Richard Gere did to Julia Roberts in “Pretty Woman”",
        "authorKey": "jared"
    },
    {
        "quote": "It could be a a twink, a bear, an otter, a circuit queen, a chub, a pup, a gipster, a daddy chaser, a leather man, a ladyboy, a Donald Duck.",
        "authorKey": "russ"
    },
    {
        "quote": "Uh, yes I can. It's all Terrain, Dummy!",
        "authorKey": "troy"
    },
    {
        "quote": "I am running away from my responsibilities. And it feels good.",
        "authorKey": "michael"
    },
    {
        "quote": "I've lived an incredible life. I saw a yeti one time, and forgot for a couple years.",
        "authorKey": "gavin"
    },
    {
        "quote": "They came here, to the New World. World 2.0, version 1776.",
        "authorKey": "richard"
    },
    {
        "quote": "We have to keep making fun of your gold chain. We have to. We don't have any other choice, Dinesh.",
        "authorKey": "gilfoyle"
    },
    {
        "quote": "And when he cried himself to sleep, I ate his British candy and told him God took it because he hated him.",
        "authorKey": "denish"
    },
    {
        "quote": "Barack Obama is scared of me! Beause I don't swallow knowledge and I spit it for free Let me clear my throat, ah ha ha ha! I don't know what that was, I don't-",
        "authorKey": "dean"
    },
    {
        "quote": "Nobody's going to downvote a guy on his birthday",
        "authorKey": "buzz"
    },
    {
        "quote": "You know Aviato?",
        "authorKey": "erlich"
    },
    {
        "quote": "The witch lost his mind.",
        "authorKey": "jian"
    },
    {
        "quote": "Hooli was like an abusive spouse to me. You know, like that guy who married Julia Roberts in “Sleeping With The Enemy”",
        "authorKey": "jared"
    },
    {
        "quote": "Synergy, bitches. Know what that means?",
        "authorKey": "russ"
    },
    {
        "quote": "viola, right into the wall.",
        "authorKey": "michael"
    },
    {
        "quote": "Is this Windows Vista bad? It's not iPhone 4 bad, is it? (sighs) F***. Don't tell me this is Zune bad.",
        "authorKey": "gavin"
    },
    {
        "quote": "Well, she's not. She's a thin, hairy regular.",
        "authorKey": "denish"
    },
    {
        "quote": "You guys ready for closure? Of your caskets!?!?",
        "authorKey": "buzz"
    },
    {
        "quote": "Sysbit Digital Solutions. Integrating open data spaces.Yeah. TechBitData Solution Systems. Creating unique cross platform technologies. Technologies. Technolo-Jesus.",
        "authorKey": "erlich"
    },
    {
        "quote": "See? This could've killed me. Now I can give it to Erlich.",
        "authorKey": "jian"
    },
    {
        "quote": "We're just here to get you rats, STAT.",
        "authorKey": "jared"
    },
    {
        "quote": "Who gives a f***? That has doors that open like this. Not like this. Or like this. So it's all f***ed.",
        "authorKey": "russ"
    },
    {
        "quote": "Don't ever, for any reason, do anything to anyone, for any reason, no matter what, no matter where, or who, or who you're with or...or where you are going, or where you have been, ever, for any reason whatsoever.",
        "authorKey": "michael"
    },
    {
        "quote": "F*** you, the audio's still working! Audio worked a hundred f***ing years ago, you f***ing piece of sh*t!",
        "authorKey": "gavin"
    },
    {
        "quote": "Look at these f***ing maggots. Crawling all over our garbage, leaving their slime of mediocrity.",
        "authorKey": "denish"
    },
    {
        "quote": "Don't be a sl*t, Richard.",
        "authorKey": "erlich"
    },
    {
        "quote": "Yes. I want his house. Thank you.",
        "authorKey": "jian"
    },
    {
        "quote": "Wikipedia is the best thing ever. Anyone in the world can write anything they want about any subject, so you know you are getting the best possible information.",
        "authorKey": "michael"
    },
    {
        "quote": "Consider the elephant. Legend has it its memory is so robust it never forgets. And I assure you, gentlemen of the Hooli board, and lady, neither do I.",
        "authorKey": "gavin"
    },
    {
        "quote": "I was going to take you to see BattleBots Live with me, but no longer friend. Now who looks stupid?",
        "authorKey": "denish"
    },
    {
        "quote": "I shan't be attending out of contempt, so this will have to serve as our goodbye. Don't let the door hit ya where God split ya.",
        "authorKey": "erlich"
    },
    {
        "quote": "Dwight you ignorant sl*t.",
        "authorKey": "michael"
    },
    {
        "quote": "It’s me! Pakistani Denzel! Yes!",
        "authorKey": "denish"
    }
]

export default QUOTES