import React, { useState, useEffect, useRef } from "react"

import { Carousel } from "react-responsive-carousel"
import { map, get, some, set } from "lodash"
import ReactGA from "react-ga4"
import { Img } from "react-image"
import Zoom from "react-reveal/Zoom"
import Alert from "@mui/material/Alert"
import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from "@mui/material/styles"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import PauseIcon from "@mui/icons-material/Pause"
import Loader from "../components/Loader"
import { useOnScreen } from "../utils/onScreenHook"
import { theme } from "../constants/Themes"
import { CAROUSEL_LOAD_DISTANCE } from "../config/settings"

export const StyledCarouselCaption = styled("div")(({ fontSize }) => ({
  position: "absolute",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  color: "white",
  fontSize,
}))

const StyledCarouselPage = styled("div")(({ fontSize }) => ({
  backgroundColor: "#000000",
  height: "100%",
  color: "white",
  fontSize,
}))

export const StyledCarouselAutoPlayCaption = styled(StyledCarouselCaption)`
  top: 0px;
  right: 0px;
  padding: 0px;
  z-index: 4;
  display: flex;
  cursor: pointer;
  font-size: 72px;
  border-radius: 0px 0px 0px 10px;
  line-height: 0px;
`

export const CarouselAutoPlayControl = ({
  isAutoPlayEnabled,
  setAutoPlayEnabled,
}) => {
  const isBtMd = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <StyledCarouselAutoPlayCaption
      onClick={() => setAutoPlayEnabled((prev) => !prev)}
    >
      <Zoom duration={2000}>
        {isAutoPlayEnabled ? (
          <PauseIcon fontSize={isBtMd ? "inherit" : "large"} />
        ) : (
          <PlayArrowIcon fontSize={isBtMd ? "inherit" : "large"} />
        )}
      </Zoom>
    </StyledCarouselAutoPlayCaption>
  )
}



const StyledCarouselSection = styled("div")`
  display: flex;
  flex-direction: column;
  background-color: black;
  margin: 4px;
  margin-top: max(
    10vh,
    calc((100vh - 64px - min(calc(88vw * 6 / 4), 88vh)) / 3)
  );
  margin-bottom: max(
    10vh,
    calc((100vh - 64px - min(calc(88vw * 6 / 4), 88vh)) / 3)
  );
`


const StyledCarouselTitle = styled("div")`
  display: flex;
  background-color: black;
  color: white;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-bottom: 2px #f2f1f0 dashed;
`



const StyledCarousel = styled(Carousel)`
  .carousel-status {
    color: white;
    right: auto;
    font-size: 24px;
    margin-top: 0px;
    padding-left: 4px;
    padding-right: 8px;
    border-radius: 0px 0px 10px 0px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 4;
  }
`

const StyledCarouselSubContainer = styled("div")`
  position: relative;
`

const isNoPosterLoading = (loadingTracker) => !some(loadingTracker)

export const StyledCarouselSectionContainer = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
`

const StatusReportingLoader = ({ index, setLoading, ...rest }) => {
  useEffect(() => {
    setLoading((prev) => set([...prev], index, true))
    return () => setLoading((prev) => set([...prev], index, false))
  }, [setLoading, index])

  return <Loader {...rest} />
}

export const EntertainmentCarousel = ({
  pictureHieght,
  pictureWidth,
  title = "",
  data = [],
  fontSize = 32,
  getUrls = () => ""
}) => {
  const [isAutoPlayEnabled, setAutoPlayEnabled] = useState(true)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [loadingTracker, setloadingTracker] = useState([])

  const carouselSectionRef = useRef()
  const carouselIsVisible = useOnScreen(carouselSectionRef)

  useEffect(() => {
    ReactGA.event({
      category: `${title} Carousel`,
      action: "Displayed",
      label: get(data, `${currentIndex}.title`, "Unkown"),
      nonInteraction: true,
    })
  }, [currentIndex])

  return (
    <StyledCarouselSection ref={carouselSectionRef}>
      <StyledCarouselTitle>{title} Rankings</StyledCarouselTitle>
      <StyledCarouselSubContainer>
        <CarouselAutoPlayControl
          isAutoPlayEnabled={isAutoPlayEnabled}
          setAutoPlayEnabled={setAutoPlayEnabled}
        />
        <StyledCarousel
          onChange={(index) => setCurrentIndex(index)}
          autoPlay={
            isNoPosterLoading(loadingTracker) &&
            isAutoPlayEnabled &&
            carouselIsVisible
          }
          infiniteLoop={true}
          interval={4000}
          showIndicators={false}
          useKeyboardArrows={true}
          width={pictureWidth}
          showThumbs={false}
          showStatus={true}
          statusFormatter={(current) => `#${current}`}
          stopOnHover={false}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={30}
        >
          {map(data, (entry, index) => {
            const posterKey = get(entry, "poster")
            const entryTitle = get(data, `${index}.title`, "unkown")
            return (
              <StyledCarouselPage
                fontSize={fontSize}
                key={`${title}_${posterKey}_${index}`}
              >
                <div
                  style={{
                    height: pictureHieght,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  {index - currentIndex < CAROUSEL_LOAD_DISTANCE && (
                    <Img
                      loader={
                        <StatusReportingLoader
                          index={index}
                          width={pictureWidth}
                          height={pictureHieght}
                          loadingDarkMode={true}
                          setLoading={setloadingTracker}
                        />
                      }
                      unloader={
                        <Alert severity="error">
                          Could not load the poster of {entryTitle}
                        </Alert>
                      }
                      alt={entryTitle}
                      width={pictureWidth}
                      height={pictureHieght}
                      src={getUrls(posterKey)}
                      style={{ width: pictureWidth, height: pictureHieght }}
                    />
                  )}
                </div>
              </StyledCarouselPage>
            )
          })}
        </StyledCarousel>
      </StyledCarouselSubContainer>
    </StyledCarouselSection>
  )
}
