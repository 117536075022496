
import React, { useState, Fragment } from 'react'

import ReactGA from 'react-ga4'
import Modal from 'react-modal'
import { get } from 'lodash'
import { Spin as Hamburger } from 'hamburger-react'
import RevealFade from 'react-reveal/Fade'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Fade from '@mui/material/Fade'
import useMediaQuery from '@mui/material/useMediaQuery'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'

const navLinks = [
    {
      display: "Home",
      url: "home",
    },
    {
      display: "About",
      url: "about",
    },
    {
      display: "Resume",
      url: "resume",
    },
    {
      display: "Projects",
      url: "projects",
    },
    {
      display: "Entertainment",
      url: "entertainment",
    },
    {
      display: "Contact",
      url: "contact",
    },
  ]

const modalStyles =  {
  content: {
    top: '0px',
    left: '0px',
    right: 'auto',
    bottom: 'auto',
    backgroundColor: '#FAFAFA',
  },
  overlay: {
      marginTop: '64px',
      webkitBackdropFilter: 'blur(5px)',
      backdropFilter: 'blur(5px)',
      backgroundColor:'rgba(253,255,255,0.44)',
      minHeight: '120vh',
      zIndex: 4000,
  },
} 

const scrollToSection = (refs, refKey) => {
  window.scrollTo({
    top: get(refs, `${refKey}.current.offsetTop`, 64) - 64,
    behavior: "smooth"
  })
  ReactGA.event({
    category: "Navigation",
    action: "Goto",
    label: refKey,
    nonInteraction: false,
  })
}
const StyledLogo = styled('img')`
  width: 48px;
  height: 48px;
  position: absolute;
  left: 10px;
  @media only screen and (max-width: 849px) {
    left: auto;
    right: 10px;
  }
`

const StyledButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`

const TopBar = ({ refs }) => {
    const isBtMd = useMediaQuery('(min-width:850px)')
    const [isMenuOpen, setMenuOpen] = useState(false)

    return (
        <Box sx={{ flexGrow: 1,position: 'absolute', index: 100, backgroundColor: '#FAFAFA' }}>
            <AppBar sx={{ zIndex: 800}}>
                <Toolbar sx={{ backgroundColor: 'white' , color: "primary", minHeight: 64 }}>
                  <StyledLogo data-tip="Matt Almenshad" src="/logo.png" alt="" width="48px" height="48px"/>
                  {isBtMd ?
                    (
                      <StyledButtonWrapper pageview="/home">
                        <Fade in={true} timeout={1600}>
                          <Stack spacing={2} direction="row">
                              {
                                navLinks.map(link =>
                                  <Button key={`bar_navLinks_${link.display}`} variant="outlined" color="primary" onClick={()=> {
                                      scrollToSection(refs, link.url)
                                  }}>
                                    {link.display}
                                  </Button>
                                )
                              }
                          </Stack>
                        </Fade>
                      </ StyledButtonWrapper>
                    ) : (
                      <Hamburger
                        toggled={isMenuOpen}
                        toggle={setMenuOpen}
                        direction="right"
                        color="black"
                        rounded
                      />
                    )
                  }
                </Toolbar>
            </AppBar>
            <Modal
              isOpen={isMenuOpen}
              onRequestClose={() => setMenuOpen(false)}
              style={modalStyles}
            >
             <RevealFade cascade>
              <MenuList sx={{backgroundColor: '#FAFAFA', textAlign: 'center'}}>
                {
                  navLinks.map((link, index) =>
                    <Fragment key={`HOVER_MENU_LINK_${index}`}>
                      {!!index && <Divider /> }
                      <MenuItem onClick={()=> {
                          scrollToSection(refs, link.url)
                          setMenuOpen(false)
                        }}>
                          <ListItemText>
                            {link.display}
                          </ListItemText>
                      </MenuItem>
                    </Fragment>
                  )
                }
              </MenuList>
              </RevealFade>
            </ Modal>
        </Box>
    )
}

export default TopBar