// constants
export const CAROUSEL_LOAD_DISTANCE = 3
export const REACT_GA_TRACKING_ID = "G-27SMXP6WZM"

// An exact copy of the website is availabe on each host
// An empty string servers as "current host" by falling back to the relative path
// The order is important, preferred hosts come first
export const hostUrls = [
    "https://vino4-github-io.pages.dev",
    "https://vino4-github-io.netlify.app",
    ".",
]
