import { FaRust } from 'react-icons/fa';
import {
    SiSolidity,
    SiElixir,
    SiJavascript,
    SiGo,
    SiNodedotjs,
    SiJava,
    SiCsharp,
    SiCplusplus,
    SiC,
    SiRubyonrails,
    SiPython,
    SiErlang,
    SiR,
    SiScala,
    SiSwift,
    SiSelenium,
    SiGatling,
    SiApachejmeter,
    SiCypress,
    SiPhp,
    SiReact,
    SiGraphql,
    SiRedux,
    SiTypescript,
    SiHtml5,
    SiCss3,
    SiSass,
    SiLess,
    SiGoogleanalytics,
    SiGrafana,
    SiLatex,
    SiHaskell,
    SiGit,
    SiGithub,
    SiAmazons3,
    SiApollographql,
    SiBuildkite,
    SiCircleci,
    SiLinux,
    SiHotjar,
    SiJira,
    SiOkta,
    SiConfluence,
    SiInsomnia,
    SiReactrouter,
    SiReacttable,
    SiSemver,
    SiYarn,
    SiPostman,
    SiPytest,
    SiPhpmyadmin,
    SiJss,
    SiDocker,
    SiAngularjs,
    SiDjango,
    SiAuth0,
    SiEslint,
    SiOpenssl,
    SiOpengl,
    SiWebgl,
    SiWireshark,
    SiGatsby,
    SiElectron,
    SiArduino,
    SiApache,
    SiCmake,
    SiNginx,
    SiQt,
    SiSketch,
    SiBlender,
    SiJekyll,
    SiCoda,
    SiJquery,
    SiKotlin,
    SiNetlify,
    SiBluetooth,
    SiMongodb,
    SiRedis,
    SiCouchbase,
    SiFilezilla,
    SiJupyter,
    SiAdobephotoshop,
    SiGimp,
    SiRaspberrypi,
    SiXaml,
    SiFirebase,
    SiJenkins,
    SiJson,
    SiPostgresql,
    SiMysql,
    SiSqlite,
    SiAmazonaws,
    SiNpm,
} from 'react-icons/si';
import { get } from 'lodash'

const TODAY_DATE = new Date();

const skillTitles = ["Novice", "Proficient", "Adept", "Expert"]
export const getSkillTitle = (level) => get(skillTitles, level - 1, "Novice")

export const WORK_EXPERIENCE = [
    {
        from: "7/23/2018",
        to: TODAY_DATE.toLocaleDateString('en-US'),
        title: "Software Engineer",
        company: "First Foundry, Inc.",
        highlights: [
            "Collaborated with the product team to design and deliver systems and features while serving as a project tech-lead.",
            "Took on additional duties during the scaling of projects to include project management, technical management and cross-team coordination.",
            "Trained new and upcoming engineers as individual contributors & team leads.",
            "Contributed to multiple highly-scalable backends capable of reliably handling daily requests in the order of millions.",
            "Led various frontend teams working on modern SPAs for large-scale projects.",
            "Led the hardware research & integration efforts for a point of sale product.",
            "Contributed to mobile react native applications.",
            "Contributed to market & product research efforts by curating feedback directly from clients and creating corresponding technical solutions and specifications.",
            "Organized monthly events to promote growth and leadership skills.",
            "Wrote internal tools & bots to facilitate communication between backend, frontend and QA teams.",
            "Devised new processes & process improvements to boost efficiency across a project.",
            "Wrote comprehensive unit tests, integration tests and E2E tests to ensure continuous quality of services and platforms.",
        ]
    },
    {
        from: "1/14/2013",
        to: "6/30/2018",
        title: "Computer Science Tutor",
        company: "University Of Oregon",
        highlights: [
            "Tutored non-technical students from various majors in entry-level software development.",
        ]
    },
    {
        from: "6/20/2012",
        to: "9/21/2012",
        title: "Training Center Manager",
        company: "KFI International Airport",
        highlights: [
            "Managed a training center in a multi-airport merger operation & developed a localized web-based training video streaming solution improving the operation.",
            "The solution enabled my center to train 300 more employees compared to the second center (a 19% increase).",
        ]
    },
    {
        from: "1/25/2009",
        to: "6/1/2012",
        title: "Computer Lab Assistant",
        company: "High School",
        highlights: [
            "Managed a computer lab.",
            "Responsibilities included computer/local network maintenance, event managing and computer basics tutoring.",
        ]
    },
    {
        from: "2/6/2006",
        to: "11/30/2012",
        title: "Business owner",
        company: "Personal Computer Repair and Web Development Service",
        highlights: [
            "Owned & operated the fastest, most affordable, computer repair and only web development service in the city.",
        ]
    },
]

export const LANGUAGES = [
    {"title": "Javascript", icon: SiJavascript, "level": 4},
    {"title": "Typescript", icon: SiTypescript, "level": 4},
    {"title": "Graphql","icon": SiGraphql,"level": 4},
    {"title": "HTML5", icon: SiHtml5, "level": 4},
    {"title": "CSS3","icon": SiCss3,"level": 4},
    {"title": "Xaml", icon: SiXaml, "level": 4},
    {"title": "Sass","icon": SiSass,"level": 4},
    {"title": "Less","icon": SiLess,"level": 4},
    {"title": "Scala","icon": SiScala,"level": 3},
    {"title": "C++", "icon": SiCplusplus, "level": 3},
    {"title": "C", "icon": SiC, "level": 3},
    {"title": "Python","icon": SiPython,"level": 3},
    {"title": "Swift","icon": SiSwift,"level": 3},
    {"title": "PHP","icon": SiPhp,"level": 3},
    {"title": "Java", "icon": SiJava, "level": 3},
    {"title": "Rust", "icon": FaRust, "level": 2},
    {"title": "Ruby On Rails","icon": SiRubyonrails,"level": 1},
    {"title": "C#", "icon": SiCsharp, "level": 1},
    {"title": "Go", "icon": SiGo, "level": 1},
    {"title": "Latex","icon": SiLatex,"level": 1},
    {"title": "Kotlin","icon": SiKotlin,"level": 1},
    {"title": "Solidity", "icon": SiSolidity, "level": 0},
    {"title": "Haskell", "icon": SiHaskell, "level": 0},
    {"title": "Elixir", "icon": SiElixir, "level": 0},
    {"title": "Erlang","icon": SiErlang,"level": 0},
    {"title": "R","icon": SiR,"level": 0},
]

export const TOOLS_AND_FRAMEWORKS = [
    {"title": "Git", "icon": SiGit, "level": 4},
    {"title": "Github", "icon": SiGithub, "level": 4},
    {"title": "Node.js", "icon": SiNodedotjs, "level": 4},
    {"title": "React","icon": SiReact,"level": 4},
    {"title": "Redux","icon": SiRedux,"level": 4},
    {"title": "AmazonS3","icon": SiAmazons3,"level": 4},
    {"title": "ApolloGraphQL","icon": SiApollographql,"level": 4},
    {"title": "Buildkite","icon": SiBuildkite,"level": 4},
    {"title": "CircleCI","icon": SiCircleci,"level": 4},
    {"title": "Linux","icon": SiLinux,"level": 4},
    {"title": "Hotjar","icon": SiHotjar,"level": 4},
    {"title": "Jira","icon": SiJira,"level": 4},
    {"title": "Okta","icon": SiOkta,"level": 4},
    {"title": "Confluence","icon": SiConfluence,"level": 4},
    {"title": "ESlint","icon": SiEslint,"level": 4},
    {"title": "Insomnia","icon": SiInsomnia,"level": 4},
    {"title": "Reactrouter","icon": SiReactrouter,"level": 4},
    {"title": "ReactTable","icon": SiReacttable,"level": 4},
    {"title": "Semver","icon": SiSemver,"level": 4},
    {"title": "Yarn","icon": SiYarn,"level": 4},
    {"title": "NPM","icon": SiNpm,"level": 4},
    {"title": "AWS", "icon": SiAmazonaws, "level": 3},
    {"title": "Google Analytics", "icon": SiGoogleanalytics, "level": 3},
    {"title": "Postman","icon": SiPostman,"level": 3},
    {"title": "Pytest","icon": SiPytest,"level": 3},
    {"title": "PHPMyAdmin","icon": SiPhpmyadmin,"level": 3},
    {"title": "JSS","icon": SiJss,"level": 3},
    {"title": "Docker","icon": SiDocker,"level": 3},
    {"title": "AngularJS","icon": SiAngularjs,"level": 3},
    {"title": "Django","icon": SiDjango,"level": 3},
    {"title": "Auth0","icon": SiAuth0,"level": 3},
    {"title": "Firebase","icon": SiFirebase,"level": 3},
    {"title": "Openssl","icon": SiOpenssl,"level": 3},
    {"title": "OpenGL","icon": SiOpengl,"level": 2},
    {"title": "WebGL","icon": SiWebgl,"level": 2},
    {"title": "Grafana", "icon": SiGrafana, "level": 2},
    {"title": "Wireshark","icon": SiWireshark,"level": 2},
    {"title": "Gatsby","icon": SiGatsby,"level": 2},
    {"title": "Electron","icon": SiElectron,"level": 2},
    {"title": "Arduino","icon": SiArduino,"level": 2},
    {"title": "Apache","icon": SiApache,"level": 2},
    {"title": "CMake","icon": SiCmake,"level": 2},
    {"title": "Nginx","icon": SiNginx,"level": 2},
    {"title": "Qt","icon": SiQt,"level": 1},
    {"title": "Sketch","icon": SiSketch,"level": 1},
    {"title": "Jenkins","icon": SiJenkins,"level": 1},
    {"title": "Jekyll","icon": SiJekyll,"level": 1},
    {"title": "Coda","icon": SiCoda,"level": 1},
    {"title": "Jquery","icon": SiJquery,"level": 1},
    {"title": "Netlify","icon": SiNetlify,"level": 1},
    {"title": "Selenium","icon": SiSelenium,"level": 1},
    {"title": "Gatling","icon": SiGatling,"level": 0},
    {"title": "JMeter","icon": SiApachejmeter,"level": 0},
    {"title": "Cypress","icon": SiCypress,"level": 0},
]

export const TECHNOLOGIES = [
    {"title": "JSON","icon": SiJson,"level": 4},
    {"title": "Bluetooth","icon": SiBluetooth,"level": 3},
]

export const DATABASES = [
    {"title": "MongoDB","icon": SiMongodb,"level": 4},
    {"title": "PostgresQL","icon": SiPostgresql,"level": 4},
    {"title": "MySQL","icon": SiMysql,"level": 4},
    {"title": "SQLite","icon": SiSqlite,"level": 4},
    {"title": "Redis","icon": SiRedis,"level": 3},
    {"title": "Couchbase","icon": SiCouchbase,"level": 1},
]

export const MISC = [
    {"title": "Filezilla","icon": SiFilezilla,"level": 4},
    {"title": "Jupyter","icon": SiJupyter,"level": 3},
    {"title": "Adobephotoshop","icon": SiAdobephotoshop,"level": 2},
    {"title": "Gimp","icon": SiGimp,"level": 2},
    {"title": "Blender","icon": SiBlender,"level": 1},
    {"title": "RaspberryPi","icon": SiRaspberrypi,"level": 1},
]

export const HACKATHONS = [
    {
        name: "InsomniHack",
        date: "11/2018",
        connection: "Mentored at",
        location: "Eugene, OR",
    },
    {
        name: "Hack For A Cause",
        date: "04/2018",
        connection: "Participated at",
        location: "Eugene, OR",
    },
    {
        name: "MLBAM",
        date: "02/2017",
        connection: "Won first place at",
        location: "New York, NY",
    },
    {
        name: "QuackCon",
        date: "08/2016",
        connection: "Co-organized",
        location: "Eugene, OR",
    },
]