import { map } from 'lodash'
import { hostUrls } from '../config/settings'

export const PROJECT_PICTURES_PATH = `project_posters`

export const getProjectPosterWithPath = (poster) => `${PROJECT_PICTURES_PATH}/${poster}`
export const getProjectPosterUrls = (poster) => map(hostUrls, (url) => `${url}/${getProjectPosterWithPath(poster)}`)

export const PROJECTS = [
    {
        "title": "Merigo",
        "poster": "merigo.png",
        "description": "Merigo is a platform which enables game developers to bootstrap reliable, scalable and easy to develop backends for their games with an incredibly fast turn-around relative to the game development industry.",
        "links": [
            {
                "text": "Check it out!",
                "url": "http://www.merigo.co/",
            },
        ],
    },
    {
        "title": "Bloom POS",
        "poster": "bloom.png",
        "description": "Bloom POS is a reliable, compliant point of sale complete with offline transactions, real-time searching and online delivery features.",
    },
    {
        "title": "RallyBoss",
        "poster": "rallyboss.png",
        "description": "RallyBoss is a Twitch extension where the community can work together to defeat Bosses and earn rewards. Players collect custom streamer-created Rally Cards and play them to unlock rewards for the whole channel.",
    },
    {
        "title": "Project Edison Drone",
        "poster": "edison.png",
        "description": "This project aims to build a mobile phone controlled IoT drone prototype operated by Intel Edison.",
        "links": [
            {
                "text": "Watch it in action!",
                "url": "https://youtu.be/ckAheYrLVow",
            },
            {
                "text": "Checkout the code!",
                "url": "https://github.com/Vino4/PED",
            },
        ],
    },
    {
        "title": "Asteroids Reloaded",
        "poster": "astroids_reloaded.png",
        "description": "This non-commercial project is an HTML5 and JS based version of one of my favourite games during my childhood, asteroids.",
        "links": [
            {
                "text": "Try it now!",
                "url": "https://vino4-github-io.pages.dev/asteroids_reloaded",
            },
            {
                "text": "Checkout the code!",
                "url": "https://github.com/Vino4/Asteroids_Reloaded",
            },
        ],
    },
    {
        "title": "Groupify",
        "poster": "groupify.png",
        "description": "A web application to help activity organizers divide participants into different teams.",
        "links": [
            {
                "text": "Checkout the code!",
                "url": "https://github.com/Vino4/Groupify",
            },
        ],
    },
    {
        "title": "IoT-Based Secure Surveillance System",
        "poster": "ibsss.png",
        "description": "This project aims to build a surveillance framework for a smart home owner. The framework is consistent of multiple nodes composed of a camera as well as an Intel Edison or a Raspberry Pi connected to a central server that manages users and streams.",
        "links": [
            {
                "text": "Checkout the code!",
                "url": "https://github.com/Vino4/IBSSS",
            },
        ],
    },
    {
        "title": "Whitebird Directory",
        "poster": "whitebird.png",
        "description": "As a contribution to the Hack for a Cause hackathon (2018), this website searches for Human Services from the Whitebird Clinic database and provides useful information to people who seek help.",
        "links": [
            {
                "text": "Checkout the code!",
                "url": "https://github.com/Vino4/h4c",
            },
        ],
    },
    {
        "title": "Graphics Rasterizer",
        "poster": "graphics_rasterizer.png",
        "description": "As a class project, this graphic rasterizer uses VTK to render 2D and 3D images from a VTK dataset. The program rasterizes colors and handle depth buffers using the Phong shading model.",
        "links": [
            {
                "text": "Checkout the code!",
                "url": "https://github.com/Vino4/UO_Computer_Graphics",
            },
        ],
    },
    {
        "title": "Network Driver",
        "poster": "network_driver.png",
        "description": "As a class project, this multi-threaded program simulates a network driver with an emphasis on the functionality of blocking and non-blocking calls.",
        "links": [
            {
                "text": "Checkout the code!",
                "url": "https://github.com/Vino4/UO_Network_Driver",
            },
        ],
    },
    {
        "title": "Process Scheduler",
        "poster": "process_scheduler.png",
        "description": "As a class project, this multi-threaded program simulates a CPU process scheduler by managing processes through multiple different techniques and data structures.",
        "links": [
            {
                "text": "Checkout the code!",
                "url": "https://github.com/Vino4/UO_Process_Scheduler",
            },
        ],
    },
    {
        "title": "MIPS Simulator",
        "poster": "mips_simulator.png",
        "description": "As a class project, this program simulates a low-level MIPS processor by reading and executing MAIPS instructions. The pipe-line is simulated using multi-threaded components where the threads inter-communicate and work in parallel to approximate the results of a real MIPS processor.",
    },
    {
        "title": "SRPC Over gRPC",
        "poster": "srpc_over_grpc.png",
        "description": "Contributed to research in an effort to bring the security of Google’s RPC protocol to the simple SRPC protocol.",
    },
    {
        "title": "Laser Tripwire",
        "poster": "laser_tripwire.gif",
        "description": "As a fun class project, we created a laser tripwire that sends an alarm to a server when the laser is intercepted logging the time of the incident.",
        "links": [
            {
                "text": "Watch it in action!",
                "url": "https://youtu.be/m9HSBq5ip6c",
            },
            {
                "text": "Checkout the code!",
                "url": "https://github.com/Vino4/UO_Laser_Tripwire",
            },
        ],
    },
]

export default PROJECTS