import React from "react"

import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from "@mui/material/styles"
import Divider from "@mui/material/Divider"

import { RevealingDimSection, DarkHeader } from "../components/Section"
import {
  StyledCarouselSectionContainer,
  EntertainmentCarousel,
} from "../components/Carousel"
import { theme } from "../constants/Themes"
import MOVIES, { getMoviePosterUrls } from "../constants/Movies"
import TV_SHOWS, { getTvPosterUrls } from "../constants/TvShows"
import AUDIO_BOOKS, { getAudioBookPosterUrls } from "../constants/AudioBooks"

const StyledDarkHeader = styled(DarkHeader)`
  margin-bottom: 0px;
  padding-bottom: 0px;
`

const EntertainmentSection = ({ innerRef }) => {
  const isBtMd = useMediaQuery(theme.breakpoints.up("md"))
  const pictureWidth = "min(88vw, 88vh*4/6)"
  const pictureHieght = "min(calc(88vw*6/4), 88vh)"
  const fontSize = isBtMd ? 22 : 16

  return (
    <div ref={innerRef}>
      <RevealingDimSection pageview="/entertainment">
        <StyledDarkHeader> Entertainment </StyledDarkHeader>
        <StyledCarouselSectionContainer>
            <EntertainmentCarousel
              pictureHieght={pictureHieght}
              pictureWidth={pictureWidth}
              title="Tv Show"
              data={TV_SHOWS}
              fontSize={fontSize}
              getUrls={getTvPosterUrls}
            />
            <Divider />
            <EntertainmentCarousel
              pictureHieght={pictureHieght}
              pictureWidth={pictureWidth}
              title="Movie"
              data={MOVIES}
              fontSize={fontSize}
              getUrls={getMoviePosterUrls}
            />
            <Divider />
            <EntertainmentCarousel
              pictureHieght={pictureHieght}
              pictureWidth={pictureWidth}
              title="Audio Book"
              data={AUDIO_BOOKS}
              fontSize={fontSize}
              getUrls={getAudioBookPosterUrls}
            />
        </StyledCarouselSectionContainer>
      </RevealingDimSection>
    </div>
  )
}

export default EntertainmentSection
