import { useState, useEffect } from 'react'

export const useOnScreen = (ref, threshold = 0.6) => {

    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      { threshold }
    )
  
    useEffect(() => {
      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect() }
      // eslint-disable-next-line
    }, [ref])
  
    return isIntersecting
  }