import React from 'react'

import { styled } from '@mui/material/styles'
import { keyframes } from "@emotion/react"
import Rating from '@mui/material/Rating'
import StarIcon from '@mui/icons-material/Star'
import { BiError } from 'react-icons/bi'
import Fade from 'react-reveal/Fade'
import { getSkillTitle } from '../../constants/Resume'

const FloatingKeyframes = keyframes`
0% { transform: translate(0,  0px); }
50%  { transform: translate(0, 4px); }
100%   { transform: translate(0, -0px); }   
`

const KeyframedCard = styled(`div`)((({ floatDuration = 5 }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: '0 0 0',
  justifyContent: 'center',
  alignItems: 'center',
  justifyItems: 'center',
  textAlign: 'center',
  fontSize: '32px',
  animationName: FloatingKeyframes,
  animationDuration: `${floatDuration}s`,
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
  background: '#000000',
  color: '#FFAA00',
  padding: '10px',
  borderRadius: '0px 0px 15px 15px',
  margin: '4px',
})))

export const ResponsiveKeyframedCard = styled(KeyframedCard)`
  @media only screen and (max-width: 700px) {
      font-size: 32px;
  }

  @media only screen and (max-width: 650px) {
      font-size: 20px;
  }

  @media only screen and (max-width: 500px) {
      font-size: 20px;
  }
`

export const StyledBadgeContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 30vw;
  align-content: center;
  justify-content: center;
  align-items: center;
  border: 3px black;
  border-style: hidden double double double;
  border-radius: 0px 0px 5px 5px;
  background-color: #ffffff;
  height: 100%;
  
  @media only screen and (max-width: 820px) {
    width: 98vw;
  }
`

const StyledBadgeContainerWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 2px;
  height: 100%;
`

const StyledBadgeContainerTitle = styled('div')`
  text-align: center;
  width: 100%;
  background-color: black;
  color: white;
  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
  animation: shimmer 20s infinite;
  @keyframes shimmer {
    8% {-webkit-mask-position:left}
    100% {-webkit-mask-position:left}
  }
  @media only screen and (max-width: 700px) {
      font-size: 16px;
  }

  @media only screen and (max-width: 300px) {
      font-size: 12px;
  }
`

const StyledRating = styled(Rating)`
  position: absolute;
  top: -4px;
  background-color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px white solid;
  border-color: #FFAA00;
  font-size: 13px;

  @media only screen and (max-width: 650px) {
       top: 0px;
      font-size: 10px;
  }
`

export const BadgeContainer = ({children, title, ...rest}) => (
  <StyledBadgeContainerWrapper>
    <StyledBadgeContainerTitle>
      {title}
    </StyledBadgeContainerTitle>
    <StyledBadgeContainer {...rest}>
      {children}
    </StyledBadgeContainer>
  </StyledBadgeContainerWrapper>
)

export const Badge = ({
  title = "",
  Icon = BiError,
  delay = 0,
  level = 0,
}) => (
  <Fade delay={delay} >
    <ResponsiveKeyframedCard
      data-tip={`${title} - Skill level: ${getSkillTitle(level)}`}
      duration={delay}
      floatDuration={!!level && (6/level)}
    >
      <Icon />
      <StyledRating
        name="read-only"
        value={level}
        size="small"
        max={4}
        icon={<StarIcon fontSize="inherit" />}
        readOnly
      />
    </ResponsiveKeyframedCard>
  </Fade>
)

export default Badge