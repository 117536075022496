import { get, map } from 'lodash'
import { hostUrls } from '../config/settings'

export const TV_SHOWS_PICTURES_PATH = `tv_show_posters`

export const getTvPosterWithPath = (poster) => `${TV_SHOWS_PICTURES_PATH}/${poster}`
export const getTvPosterUrls = (poster) => map(hostUrls, (url) => `${url}/${getTvPosterWithPath(poster)}`)

export const TV_SHOWS = [
    {
        "title": "Spartacus",
        "poster": "spartacus.png"
    },
    {
        "title": "Community",
        "poster": "community.png"
    },
    {
        "title": "The Office",
        "poster": "the_office.png"
    },
    {
        "title": "The Magicians",
        "poster": "the_magicians.png"
    },
    {
        "title": "The Originals",
        "poster": "the_originals.png"
    },
    {
        "title": "Halt and Catch Fire",
        "poster": "halt_and_catch_fire.png"
    },
    {
        "title": "How I Met Your Mother",
        "poster": "how_i_met_your_mother.png"
    },
    {
        "title": "The Vampire Diaries",
        "poster": "the_vampire_diaries.png"
    },
    {
        "title": "Good Trouble",
        "poster": "good_trouble.png"
    },
    {
        "title": "The boys",
        "poster": "the_boys.png"
    },
    {
        "title": "Squid Game",
        "poster": "squid_game.png"
    },
    {
        "title": "La Casa De Papel",
        "poster": "la_casa_de_papel.png"
    },
    {
        "title": "American Gods",
        "poster": "american_gods.png"
    },
    {
        "title": "Psych",
        "poster": "psych.png"
    },
    {
        "title": "That '70s Show",
        "poster": "that_70_s_show.png"
    },
    {
        "title": "True Detective",
        "poster": "true_detective.png"
    },
    {
        "title": "Seinfeld",
        "poster": "seinfeld.png"
    },
    {
        "title": "Parks and Recreations",
        "poster": "parks_and_recreations.png"
    },
    {
        "title": "Prison Break",
        "poster": "prison_break.png"
    },
    {
        "title": "Better Call Saul",
        "poster": "better_call_saul.png"
    },
    {
        "title": "Dark Matter",
        "poster": "dark_matter.png"
    },
    {
        "title": "Silicon Valley",
        "poster": "silicon_valley.png"
    },
    {
        "title": "The Good Place",
        "poster": "the_good_place.png"
    },
    {
        "title": "Breaking Bad",
        "poster": "breaking_bad.png"
    },
    {
        "title": "Leverage",
        "poster": "leverage.png"
    },
    {
        "title": "Constantine",
        "poster": "constantine.png"
    },
    {
        "title": "House of Cards",
        "poster": "house_of_cards.png"
    },
    {
        "title": "White Collar",
        "poster": "white_collar.png"
    },
    {
        "title": "Arcane",
        "poster": "arcane.png"
    },
    {
        "title": "Shark Tank",
        "poster": "shark_tank.png"
    },
    {
        "title": "Cobra Kai",
        "poster": "cobra_kai.png"
    },
    {
        "title": "The Queen's Gambit",
        "poster": "the_queens_gambit.png"
    },
    {
        "title": "Ashes of Love",
        "poster": "ashes_of_love.png"
    },
    {
        "title": "3%",
        "poster": "3.png"
    },
    {
        "title": "Penn and Teller: Fool us",
        "poster": "penn_and_teller_fool_us.png"
    },
    {
        "title": "The Umbrella Academy",
        "poster": "the_umbrella_academy.png"
    },
    {
        "title": "Friends",
        "poster": "friends.png"
    },
    {
        "title": "Kim's Convenience",
        "poster": "kims_convenience.png"
    },
    {
        "title": "StartUp",
        "poster": "start_up.png"
    },
    {
        "title": "Space Force",
        "poster": "space_force.png"
    },
    {
        "title": "Mr. Robot",
        "poster": "mr_robot.png"
    },
    {
        "title": "Last Man Standing",
        "poster": "last_man_standing.png"
    },
    {
        "title": "The Americans (2013)",
        "poster": "the_americans_2013.png"
    },
    {
        "title": "The 100",
        "poster": "the_100.png"
    },
    {
        "title": "Love Island (UK)",
        "poster": "love_island_uk.png"
    },
    {
        "title": "Game of Thrones",
        "poster": "game_of_thrones.png"
    },
    {
        "title": "Comedians in Cars Drinking Coffee",
        "poster": "comedians_in_cars_drinking_coffee.png"
    },
    {
        "title": "Living with yourself",
        "poster": "living_with_yourself.png"
    },
    {
        "title": "Key & Peele",
        "poster": "key_peele.png"
    },
    {
        "title": "Don't trust the b in apartment 23",
        "poster": "dont_trust_the_b_in_apartment_23.png"
    },
    {
        "title": "The Cabin",
        "poster": "the_cabin.png"
    },
    {
        "title": "Grand Army",
        "poster": "grand_army.png"
    },
    {
        "title": "Good Omens",
        "poster": "good_omens.png"
    },
    {
        "title": "Buffy the Vampire Slayer",
        "poster": "buffy_the_vampire_slayer.png"
    },
    {
        "title": "Black Mirror",
        "poster": "black_mirror.png"
    },
    {
        "title": "Lost",
        "poster": "lost.png"
    },
    {
        "title": "Unbelievable",
        "poster": "unbelievable.png"
    },
    {
        "title": "The Last O.G.",
        "poster": "the_last_o_g.png"
    },
    {
        "title": "About a boy",
        "poster": "about_a_boy.png"
    },
    {
        "title": "Real Rob",
        "poster": "real_rob.png"
    },
    {
        "title": "Bones",
        "poster": "bones.png"
    },
    {
        "title": "Misfits",
        "poster": "misfits.png"
    },
    {
        "title": "Hell’s Kitchen",
        "poster": "hells_kitchen.png"
    },
    {
        "title": "GLOW",
        "poster": "glow.png"
    },
    {
        "title": "Superstore",
        "poster": "superstore.png"
    },
    {
        "title": "House",
        "poster": "house.png"
    },
    {
        "title": "Love",
        "poster": "love.png"
    },
    {
        "title": "The Flash",
        "poster": "the_flash.png"
    },
    {
        "title": "Arrow",
        "poster": "arrow.png"
    },
    {
        "title": "13 Reasons Why",
        "poster": "13_reasons_why.png"
    },
    {
        "title": "The 4400",
        "poster": "the_4400.png"
    },
    {
        "title": "Legends of Tomorrow",
        "poster": "legends_of_tomorrow.png"
    },
    {
        "title": "Glee",
        "poster": "glee.png"
    },
    {
        "title": "The Big Bang Theory",
        "poster": "the_big_bang_theory.png"
    },
    {
        "title": "The King's Avatar",
        "poster": "the_kings_avatar.png"
    },
    {
        "title": "Love, Death & Robots",
        "poster": "love_death_robots.png"
    },
    {
        "title": "The New Legends of Monkey",
        "poster": "the_new_legends_of_monkey.png"
    },
    {
        "title": "The X-Files",
        "poster": "the_x_files.png"
    },
    {
        "title": "Angel",
        "poster": "angel.png"
    },
    {
        "title": "Altered Carbon",
        "poster": "altered_carbon.png"
    },
    {
        "title": "America's Got Talent",
        "poster": "americas_got_talent.png"
    },
    {
        "title": "Friends from College",
        "poster": "friends_from_college.png"
    },
    {
        "title": "White Gold",
        "poster": "white_gold.png"
    },
    {
        "title": "Girlboss",
        "poster": "girlboss.png"
    },
    {
        "title": "The Mentalist",
        "poster": "the_mentalist.png"
    },
    {
        "title": "Fate: The Winx Saga",
        "poster": "fate_the_winx_saga.png"
    },
    {
        "title": "Wu Assassins",
        "poster": "wu_assassins.png"
    },
    {
        "title": "Bling Empire",
        "poster": "bling_empire.png"
    },
    {
        "title": "Inside Bill's Brain: Decoding Bill Gates",
        "poster": "inside_bills_brain_decoding_bill_gates.png"
    },
    {
        "title": "Dirty Money",
        "poster": "dirty_money.png"
    },
    {
        "title": "Daybreak",
        "poster": "daybreak.png"
    },
    {
        "title": "Special Correspondents",
        "poster": "special_correspondents.png"
    },
    {
        "title": "Explained",
        "poster": "explained.png"
    },
    {
        "title": "Orange Is the New Black",
        "poster": "orange_is_the_new_black.png"
    },
    {
        "title": "After Life",
        "poster": "after_life.png"
    },
    {
        "title": "Pose",
        "poster": "pose.png"
    },
    {
        "title": "Trailer Park Boys",
        "poster": "trailer_park_boys.png"
    },
    {
        "title": "Never Have I Ever",
        "poster": "never_have_i_ever.png"
    },
    {
        "title": "Atypical",
        "poster": "atypical.png"
    },
    {
        "title": "Middleditch & Schwartz",
        "poster": "middleditch_schwartz.png"
    },
    {
        "title": "American Idol",
        "poster": "american_idol.png"
    },
    {
        "title": "Sense8",
        "poster": "sense_8.png"
    },
    {
        "title": "Black Lightning",
        "poster": "black_lightning.png"
    },
    {
        "title": "Limitless",
        "poster": "limitless.png"
    },
    {
        "title": "Hunters",
        "poster": "hunters.png"
    },
    {
        "title": "Sabrina the Teenage Witch",
        "poster": "sabrina_the_teenage_witch.png"
    },
    {
        "title": "The Joel McHale Show with Joel McHale",
        "poster": "the_joel_mc_hale_show_with_joel_mc_hale.png"
    },
    {
        "title": "Mr. Iglesias",
        "poster": "mr_iglesias.png"
    },
    {
        "title": "NCIS",
        "poster": "ncis.png"
    },
    {
        "title": "Law & Order",
        "poster": "law_order.png"
    },
    {
        "title": "Criminal Minds",
        "poster": "criminal_minds.png"
    },
    {
        "title": "CSI",
        "poster": "csi.png"
    },
    {
        "title": "Search Party",
        "poster": "search_party.png"
    }
]

export const getTvShowTitle = (index) => get(TV_SHOWS, `${index}.title`, "unkown tv show")


export default TV_SHOWS