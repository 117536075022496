import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
    palette: {
      primary: {
        main: "#000000",
        primary: "#000000",
        secondary: "#ffffff",
        light: "#f2f1f0",
        lightest: "#ffffff",
        dark: "#f2f1f0",
        darkest: "#000000",
      }
    },
  })