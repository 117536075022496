import { map, get } from 'lodash'
import { hostUrls } from '../config/settings'

export const MOVIES_PICTURES_PATH = `movie_posters`

export const getMoviePosterWithPath = (poster) => `${MOVIES_PICTURES_PATH}/${poster}`
export const getMoviePosterUrls = (poster) => map(hostUrls, (url) => `${url}/${getMoviePosterWithPath(poster)}`)

export const MOVIES = [
    {
        "title": "Scarface",
        "poster": "scarface.png"
    },
    {
        "title": "Tangled",
        "poster": "tangled.png"
    },
    {
        "title": "Harry Potter",
        "poster": "harry_potter.png"
    },
    {
        "title": "The God Father",
        "poster": "the_god_father.png"
    },
    {
        "title": "The Shawshank Redemption",
        "poster": "the_shawshank_redemption.png"
    },
    {
        "title": "The Lion King",
        "poster": "the_lion_king.png"
    },
    {
        "title": "Spider-Man: No Way Home",
        "poster": "spider_man_no_way_home.png"
    },
    {
        "title": "Avengers: Endgame",
        "poster": "avengers_endgame.png"
    },
    {
        "title": "Guardians of the Galaxy Vol. 2",
        "poster": "guardians_of_the_galaxy_vol_2.png"
    },
    {
        "title": "Guardians of the Galaxy",
        "poster": "guardians_of_the_galaxy.png"
    },
    {
        "title": "Avengers: Age of Ultron",
        "poster": "avengers_age_of_ultron.png"
    },
    {
        "title": "Doctor Strange",
        "poster": "doctor_strange.png"
    },
    {
        "title": "Iron Man",
        "poster": "iron_man.png"
    },
    {
        "title": "Captain America: Civil War",
        "poster": "captain_america_civil_war.png"
    },
    {
        "title": "Léon: The Professional",
        "poster": "leon_the_professional.png"
    },
    {
        "title": "The Lord of the Rings",
        "poster": "the_lord_of_the_rings.png"
    },
    {
        "title": "The Matrix",
        "poster": "the_matrix.png"
    },
    {
        "title": "The Dark Knight",
        "poster": "the_dark_knight.png"
    },
    {
        "title": "Star Wars",
        "poster": "star_wars.png"
    },
    {
        "title": "WALL·E",
        "poster": "wall_e.png"
    },
    {
        "title": "Pirates of the Caribbean",
        "poster": "pirates_of_the_caribbean.png"
    },
    {
        "title": "Avengers: Infinity War",
        "poster": "avengers_infinity_war.png"
    },
    {
        "title": "The Pursuit of Happyness",
        "poster": "the_pursuit_of_happyness.png"
    },
    {
        "title": "Aladdin (1992)",
        "poster": "aladdin_1992.png"
    },
    {
        "title": "Inception",
        "poster": "inception.png"
    },
    {
        "title": "Princess Mononoke",
        "poster": "princess_mononoke.png"
    },
    {
        "title": "Spirited Away",
        "poster": "spirited_away.png"
    },
    {
        "title": "Up",
        "poster": "up.png"
    },
    {
        "title": "The Incredibles",
        "poster": "the_incredibles.png"
    },
    {
        "title": "Inside Out",
        "poster": "inside_out.png"
    },
    {
        "title": "Monsters University",
        "poster": "monsters_university.png"
    },
    {
        "title": "Monsters, Inc.",
        "poster": "monsters_inc.png"
    },
    {
        "title": "Beauty and the Beast",
        "poster": "beauty_and_the_beast.png"
    },
    {
        "title": "Spider-Man: Far From Home",
        "poster": "spider_man_far_from_home.png"
    },
    {
        "title": "Ant-Man",
        "poster": "ant_man.png"
    },
    {
        "title": "Marvel's The Avengers",
        "poster": "marvels_the_avengers.png"
    },
    {
        "title": "Spider-Man: Homecoming",
        "poster": "spider_man_homecoming.png"
    },
    {
        "title": "Eternals",
        "poster": "eternals.png"
    },
    {
        "title": "Iron Man 2",
        "poster": "iron_man_2.png"
    },
    {
        "title": "Iron Man 3",
        "poster": "iron_man_3.png"
    },
    {
        "title": "Megamind",
        "poster": "megamind.png"
    },
    {
        "title": "Thor: Ragnarok",
        "poster": "thor_ragnarok.png"
    },
    {
        "title": "Oceans",
        "poster": "oceans.png"
    },
    {
        "title": "Thor",
        "poster": "thor.png"
    },
    {
        "title": "Thor: The Dark World",
        "poster": "thor_the_dark_world.png"
    },
    {
        "title": "Black Panther",
        "poster": "black_panther.png"
    },
    {
        "title": "Ant-Man and the Wasp",
        "poster": "ant_man_and_the_wasp.png"
    },
    {
        "title": "Frozen",
        "poster": "frozen.png"
    },
    {
        "title": "Big Hero 6",
        "poster": "big_hero_6.png"
    },
    {
        "title": "Toy Story",
        "poster": "toy_story.png"
    },
    {
        "title": "The Invention of Lying",
        "poster": "the_invention_of_lying.png"
    },
    {
        "title": "The Wandering Earth",
        "poster": "the_wandering_earth.png"
    },
    {
        "title": "The Truman Show",
        "poster": "the_truman_show.png"
    },
    {
        "title": "Interstellar",
        "poster": "interstellar.png"
    },
    {
        "title": "Coco",
        "poster": "coco.png"
    },
    {
        "title": "How to Train Your Dragon",
        "poster": "how_to_train_your_dragon.png"
    },
    {
        "title": "The Princess and the Frog",
        "poster": "the_princess_and_the_frog.png"
    },
    {
        "title": "Mulan",
        "poster": "mulan.png"
    },
    {
        "title": "Zootopia",
        "poster": "zootopia.png"
    },
    {
        "title": "Hercules",
        "poster": "hercules.png"
    },
    {
        "title": "Ex Machina",
        "poster": "ex_machina.png"
    },
    {
        "title": "The Incredible Hulk",
        "poster": "the_incredible_hulk.png"
    },
    {
        "title": "Cloudy with a Chance of Meatballs",
        "poster": "cloudy_with_a_chance_of_meatballs.png"
    },
    {
        "title": "The Imitation Game",
        "poster": "the_imitation_game.png"
    },
    {
        "title": "6 Underground",
        "poster": "6_underground.png"
    },
    {
        "title": "Shang-Chi and the Legend of the Ten Rings",
        "poster": "shang_chi_and_the_legend_of_the_ten_rings.png"
    },
    {
        "title": "Captain America: The First Avenger",
        "poster": "captain_america_the_first_avenger.png"
    },
    {
        "title": "Wonder Woman (2017)",
        "poster": "wonder_woman_2017.png"
    },
    {
        "title": "Wreck-It Ralph",
        "poster": "wreck_it_ralph.png"
    },
    {
        "title": "Spider-Man: Into the Spider-Verse",
        "poster": "spider_man_into_the_spider_verse.png"
    },
    {
        "title": "Captain America: The Winter Soldier",
        "poster": "captain_america_the_winter_soldier.png"
    },
    {
        "title": "Howl’s Moving Castle",
        "poster": "howls_moving_castle.png"
    },
    {
        "title": "Moana",
        "poster": "moana.png"
    },
    {
        "title": "Limitless",
        "poster": "limitless.png"
    },
    {
        "title": "Terminator",
        "poster": "terminator.png"
    },
    {
        "title": "Rocky",
        "poster": "rocky.png"
    },
    {
        "title": "Django Unchained",
        "poster": "django_unchained.png"
    },
    {
        "title": "The Little Mermaid",
        "poster": "the_little_mermaid.png"
    },
    {
        "title": "Year One",
        "poster": "year_one.png"
    },
    {
        "title": "Yes Man",
        "poster": "yes_man.png"
    },
    {
        "title": "Always Be My Maybe",
        "poster": "always_be_my_maybe.png"
    },
    {
        "title": "Finding Dory",
        "poster": "finding_dory.png"
    },
    {
        "title": "Finding Nemo",
        "poster": "finding_nemo.png"
    },
    {
        "title": "21",
        "poster": "21.png"
    },
    {
        "title": "School of Rock",
        "poster": "school_of_rock.png"
    },
    {
        "title": "The Edge of Seventeen",
        "poster": "the_edge_of_seventeen.png"
    },
    {
        "title": "Groundhog Day",
        "poster": "groundhog_day.png"
    },
    {
        "title": "The Terminal",
        "poster": "the_terminal.png"
    },
    {
        "title": "Love Hard",
        "poster": "love_hard.png"
    },
    {
        "title": "The Hummingbird Project",
        "poster": "the_hummingbird_project.png"
    },
    {
        "title": "Indiana Jones",
        "poster": "indiana_jones.png"
    },
    {
        "title": "I Am Legend",
        "poster": "i_am_legend.png"
    },
    {
        "title": "Molly's Game",
        "poster": "mollys_game.png"
    },
    {
        "title": "300",
        "poster": "300.png"
    },
    {
        "title": "The Irishman",
        "poster": "the_irishman.png"
    },
    {
        "title": "Braveheart",
        "poster": "braveheart.png"
    },
    {
        "title": "The Perfect Date",
        "poster": "the_perfect_date.png"
    },
    {
        "title": "Catch Me If You Can",
        "poster": "catch_me_if_you_can.png"
    },
    {
        "title": "Mad Max",
        "poster": "mad_max.png"
    },
    {
        "title": "Back to the Future",
        "poster": "back_to_the_future.png"
    },
    {
        "title": "Parasite",
        "poster": "parasite.png"
    },
    {
        "title": "Gladiator",
        "poster": "gladiator.png"
    },
    {
        "title": "The Social Network",
        "poster": "the_social_network.png"
    },
    {
        "title": "Tarzan",
        "poster": "tarzan.png"
    },
    {
        "title": "The Jungle Book",
        "poster": "the_jungle_book.png"
    },
    {
        "title": "The Emperor’s New Groove",
        "poster": "the_emperors_new_groove.png"
    },
    {
        "title": "Fantasia",
        "poster": "fantasia.png"
    },
    {
        "title": "Bambi",
        "poster": "bambi.png"
    },
    {
        "title": "The Sound of Music",
        "poster": "the_sound_of_music.png"
    },
    {
        "title": "I Care a Lot",
        "poster": "i_care_a_lot.png"
    },
    {
        "title": "Ratatouille",
        "poster": "ratatouille.png"
    },
    {
        "title": "Don't Look Up",
        "poster": "dont_look_up.png"
    },
    {
        "title": "The Interview",
        "poster": "the_interview.png"
    },
    {
        "title": "La La Land",
        "poster": "la_la_land.png"
    },
    {
        "title": "Murder Mystery",
        "poster": "murder_mystery.png"
    },
    {
        "title": "Inglourious Basterds",
        "poster": "inglourious_basterds.png"
    },
    {
        "title": "Slumdog Millionaire",
        "poster": "slumdog_millionaire.png"
    },
    {
        "title": "Bright",
        "poster": "bright.png"
    },
    {
        "title": "Tall Girl",
        "poster": "tall_girl.png"
    },
    {
        "title": "Black Widow",
        "poster": "black_widow.png"
    },
    {
        "title": "Uncut Gems",
        "poster": "uncut_gems.png"
    },
    {
        "title": "Green Lantern",
        "poster": "green_lantern.png"
    },
    {
        "title": "The Wrong Missy",
        "poster": "the_wrong_missy.png"
    },
    {
        "title": "The Kissing Booth",
        "poster": "the_kissing_booth.png"
    },
    {
        "title": "Project Power",
        "poster": "project_power.png"
    },
    {
        "title": "Cinderella",
        "poster": "cinderella.png"
    },
    {
        "title": "102 Dalmatians",
        "poster": "102_dalmatians.png"
    },
    {
        "title": "Snow White and the Seven Dwarfs",
        "poster": "snow_white_and_the_seven_dwarfs.png"
    },
    {
        "title": "Pinocchio",
        "poster": "pinocchio.png"
    },
    {
        "title": "Sorry to Bother You",
        "poster": "sorry_to_bother_you.png"
    },
    {
        "title": "Baahubali: The Beginning (English Version)",
        "poster": "baahubali_the_beginning_english_version.png"
    },
    {
        "title": "Jaws",
        "poster": "jaws.png"
    },
    {
        "title": "The Wizard of Oz",
        "poster": "the_wizard_of_oz.png"
    },
    {
        "title": "El Camino",
        "poster": "el_camino.png"
    },
    {
        "title": "Dumbo",
        "poster": "dumbo.png"
    },
    {
        "title": "Raising Dion",
        "poster": "raising_dion.png"
    },
    {
        "title": "A Monster Calls",
        "poster": "a_monster_calls.png"
    },
    {
        "title": "Thunder Force",
        "poster": "thunder_force.png"
    },
    {
        "title": "Nymphomaniac",
        "poster": "nymphomaniac.png"
    }
]

export const getMovieTitle = (index) => get(MOVIES, `${index}.title`, "unkown movie")

export default MOVIES