import React from 'react'

import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import withReveal from 'react-reveal/withReveal'
import Fade from 'react-reveal/Fade'

import withPageview from '../utils/withPageView'

export const Header = styled(`div`)(
  () => `
    font-size: 32px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  `
)

export const LightHeader = styled(Header)(
  ({ theme }) => `
    color: ${theme.palette.primary.lightest};
  `
)

export const RevealingLightHeader = withReveal(LightHeader, <Fade duration={1200}/>)

export const DarkHeader = styled(Header)(
    ({ theme }) => `
      color: ${theme.palette.primary.darkest};
    `
  )

export const RevealingDarkHeader = withReveal(DarkHeader, <Fade duration={1200}/>)

const HookedBox = withPageview(Box)

export const  BaseSection = styled(HookedBox)(() => (`
    width: 100%;
    min-height: calc(100vh - 64px);
    align-items: center;
    align-content: center;
    display: flex;
    font-weight: bold;
    font-size: 22px;
    flex-direction: column;
    padding-bottom: 10px;
`))

export const LightSection = styled(BaseSection)(({ theme }) => (`
    background-color: ${theme.palette.primary.lightest};
`))

export const RevealingLightSection = withReveal(LightSection, <Fade duration={1200} />)

export const DimSection = styled(BaseSection)(({ theme }) => (`
    background-color: ${theme.palette.primary.dark};
`))

export const RevealingDimSection = withReveal(DimSection, <Fade duration={1200} />)

export const DarkSection = styled(BaseSection)(({ theme }) => (`
background-color: ${theme.palette.primary.darkest};
color: ${theme.palette.primary.lightest};
`))

export const RevealingDarkSection = withReveal(DarkSection, <Fade duration={1200} />)